import PropTypes from 'prop-types';
/** @jsx jsx needed to get Storybook to render */
import { css, jsx } from '@emotion/core'; // eslint-disable-line no-unused-vars
import SVG from 'react-inlinesvg';
import { breakpoints, colors, unitConverter as uc } from '../../../styles/base';
import DeprecatedGrid from '../../atoms/Grid/DeprecatedGrid';
import Link from '../../atoms/Link/Link';

const SocialIcons = ({ socialIcons }) => {
  if (socialIcons.length < 1) return null;

  const socialIconCss = css`
    width: ${uc('30px')};
    height: ${uc('30px')};
    margin-top: ${uc('15px')};
    margin-left: ${uc('30px')};
    color: ${colors.white};
    text-decoration: none;

    svg {
      display: block;
      max-width: 100%;
      max-height: 100%;
      color: ${colors.white};

      .cls-1 {
        filter: none;
      }
    }

    @media (${breakpoints.tablet}) {
      margin: ${uc('0 10px')};
    }
  `;

  const gridCss = css`
    @media (${breakpoints.tablet}) {
      justify-content: space-between;
      max-width: ${uc('230px')};
    }
  `;

  // get social links
  const renderedSocialLinks = socialIcons.map(({ link, socialIcon }) => (
    <Link key={`link-component-${link.text}`} to={link} css={socialIconCss}>
      <SVG alt="icon" src={`/socialIcons/${socialIcon}.svg`} />
    </Link>
  ));

  return (
    <DeprecatedGrid css={gridCss} justifyContent="flex-end">
      {renderedSocialLinks}
    </DeprecatedGrid>
  );
};

SocialIcons.propTypes = {
  socialIcons: PropTypes.arrayOf(PropTypes.shape({})),
};

SocialIcons.defaultProps = {
  socialIcons: [{}],
};

export default SocialIcons;

const sanityClient = require('@sanity/client');
const sanityJson = require('../../../backend/sanity.json');

const client = sanityClient({
  projectId: sanityJson.api.projectId,
  dataset: sanityJson.api.dataset,
  token: process.env.GATSBY_SANITY_AUTH_TOKEN || '', // or leave blank to be anonymous user
  useCdn: false, // `false` if you want to ensure fresh data
});

export default client;

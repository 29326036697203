import PropTypes from 'prop-types';
/** @jsx jsx needed to get Storybook to render */
import { css, jsx } from '@emotion/core';
import {
  breakpoints,
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
  unitConverter as uc,
} from '../../../../styles/base';
import DeprecatedGrid from '../../../atoms/Grid/DeprecatedGrid';
import { Heading4 } from '../../../atoms/Headings/Headings';
import Text from '../../../atoms/Text/Text';
import Button from '../../../atoms/Buttons/Button/Button';
import Image from '../../../atoms/Image/Image';
import sanityImage from '../../../../utils/sanityImage';
import FooterMenuColumn from './FooterMenuColumn';

const FooterMenu = ({
  button,
  description,
  footerMenuColumns,
  headingText,
  icon,
  phoneNumber,
}) => {
  const gridCss = css`
    display: flex;
    justify-content: space-between;
    margin-bottom: ${uc('50px')};

    > * {
      padding: ${uc('20px')};
    }

    @media (${breakpoints.tablet}) {
      flex-direction: column;
      width: 100%;
      margin-top: 0;
    }
  `;

  const imageCss = css`
    display: inline-block;
    margin-right: ${uc('20px')};
    vertical-align: top;
  `;

  const textCss = css`
    display: inline-block;
    margin-bottom: ${uc('20px')};
  `;

  const heading4Css = css`
    color: ${colors.darkGray.two};
    letter-spacing: 0;
  `;

  const descriptionCss = css`
    margin-bottom: 0;
    color: ${colors.primary};
    font-weight: ${fontWeights.medium};
    font-size: ${fontSizes.twentyThree};
    font-family: ${fontFamilies.proximaSoft};
    line-height: ${lineHeights.copy};
    text-align: center;

    @media (${breakpoints.largeTablet}) {
      text-align: left;
    }

    @media (${breakpoints.mobile}) {
      margin: 0 auto;
    }
  `;

  const firstColumnCss = css`
    flex: 1 1 0;
  `;

  const footerMenuColumnsCss = css`
    display: flex;
    flex: 3 1 0;
    justify-content: space-between;

    @media (${breakpoints.largeTablet}) {
      flex: 1 100%;
      flex-wrap: wrap;
      justify-content: left;

      > div {
        flex: 0 30%;
        margin-right: ${uc('10px')};
        margin-bottom: ${uc('20px')};
      }
    }

    @media (${breakpoints.mobile}) {
      > div {
        flex: 0 50%;
        margin-right: 0;
      }
    }
  `;

  // render menu columns
  const renderedFooterMenuColumns = footerMenuColumns.map(column => (
    <FooterMenuColumn
      key={`footer-column-component-${column._key}`}
      headingText={column.headingText}
      links={column.links}
    />
  ));

  // render phone number by stripping everything but digits
  // and adding 'tel' in front
  const renderedPhoneNumber = phoneNumber
    ? `tel: ${phoneNumber.replace(/\D/g, '')}`
    : null;

  return (
    <DeprecatedGrid css={gridCss}>
      <div css={firstColumnCss}>
        {icon.asset && (
          <Image
            css={imageCss}
            src={sanityImage(icon)
              .auto('format')
              .width(35)
              .url()}
          />
        )}
        <div css={textCss}>
          <Heading4 css={heading4Css}>{headingText}</Heading4>
          {button.text && (
            <Button _key="footer" {...button}>
              {button.text}
            </Button>
          )}
        </div>
        {description && <Text css={descriptionCss}>{description}</Text>}
        {renderedPhoneNumber && (
          <a css={descriptionCss} href={renderedPhoneNumber}>
            {phoneNumber}
          </a>
        )}
        <Text />
      </div>
      <div css={footerMenuColumnsCss}>{renderedFooterMenuColumns}</div>
    </DeprecatedGrid>
  );
};

/**
 * Prop types
 */
FooterMenu.propTypes = {
  icon: PropTypes.instanceOf(Object),
  headingText: PropTypes.string,
  button: PropTypes.instanceOf(Object),
  description: PropTypes.string,
  footerMenuColumns: PropTypes.arrayOf(PropTypes.shape({})),
  phoneNumber: PropTypes.string,
};

/**
 * Default Props
 */
FooterMenu.defaultProps = {
  icon: {},
  headingText: null,
  button: {},
  description: null,
  footerMenuColumns: [],
  phoneNumber: null,
};

export default FooterMenu;

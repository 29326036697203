/** @jsx jsx needed to get Storybook to render */
import { jsx } from '@emotion/core';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { LocaleContext } from '../../../context/LocaleContext';
import useLocalize from '../../../hooks/useLocalize';
import Footer from './Footer';

const GatsbyFooter = ({ footer }) => {
  // get menu query for footer menu
  const footerMenuQuery = graphql`
    {
      sanityFooterMenu(footerMenuName: { eq: "Footer Menu" }) {
        _rawIcon(resolveReferences: { maxDepth: 10 })
        headingText: _rawHeadingText(resolveReferences: { maxDepth: 100 })
        _rawButton(resolveReferences: { maxDepth: 10 })
        description: _rawDescription(resolveReferences: { maxDepth: 100 })
        phoneNumber: _rawPhoneNumber(resolveReferences: { maxDepth: 100 })
        _rawFooterMenuColumns(resolveReferences: { maxDepth: 100 })
        _rawSubscribeForm(resolveReferences: { maxDepth: 100 })
        _rawSocialIcons(resolveReferences: { maxDepth: 100 })
        _rawCopyright(resolveReferences: { maxDepth: 100 })
      }
    }
  `;

  const rawData = useStaticQuery(footerMenuQuery) || {};
  const { locale } = useContext(LocaleContext);
  const { sanityFooterMenu = {} } = useLocalize(rawData, locale);

  return <Footer sanityFooterMenu={footer || sanityFooterMenu} />;
};

GatsbyFooter.propTypes = {
  footer: PropTypes.shape({}),
};

GatsbyFooter.defaultProps = {
  footer: null,
};

export default GatsbyFooter;

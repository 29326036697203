import PropTypes from 'prop-types';
/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';
import { useState } from 'react';
import Cookies from 'js-cookie';
import { renderButtons } from '../../../utils/buttonUtils';

import {
  backgroundColors,
  breakpoints,
  buttonSpacing,
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  letterSpacings,
  unitConverter as uc,
} from '../../../styles/base';

import sanityImage from '../../../utils/sanityImage';
import Image from '../../atoms/Image/Image';

import { Heading2 } from '../../atoms/Headings/Headings';
import NewsBarToggle from './NewsBarToggle';
import PageWrapper from '../../organisms/PageWrapper/PageWrapper';
import Section from '../Section/Section';
import Text from '../../atoms/Text/Text';

const NewsBarLarge = ({
  backgroundColor,
  buttons,
  closeText,
  description,
  heading,
  notificationBarImage,
}) => {
  const textColor =
    backgroundColor === 'white' ? colors.darkGray : colors.white;

  const padAnimatedBtn =
    buttons.length === 2 && buttons[0]._type === 'animatedButton';

  const newsBarWrapper = css`
    display: flex;
    flex-direction: column-reverse;

    #toggle {
      position: absolute;
      top: -100%;
      left: -100%;
    }

    #toggle + label {
      display: block;
      flex-basis: 100%;
      height: ${uc('35px')};
      color: ${textColor};
      text-align: center;
      background: ${backgroundColors.darkBlue};
      cursor: pointer;
      appearance: none;
    }

    label::before {
      display: inline-block;
      padding: ${uc('12px')};
      font-weight: ${fontWeights.bold};
      font-size: ${fontSizes.twelve};
      font-family: ${fontFamilies.proximaSoft};
      content: '${closeText.toUpperCase()}';
    }

    label::after {
      position: absolute;
      display: inline-block;
      padding-top: ${uc('19px')};
      font-size: ${fontSizes.twentyFive};
      font-family: ${fontFamilies.proximaSoft};
      content: '^';
    }

    #toggle:checked + label::before {
      display: inline-block;
      font-weight: ${fontWeights.bold};
      font-size: ${fontSizes.twelve};
      font-family: ${fontFamilies.proximaSoft};
      content: '${heading}';
    }

    #toggle:checked + label::after {
      position: absolute;
      display: inline-block;
      padding-top: ${uc('17px')};
      font-weight: ${fontWeights.bold};
      font-size: ${fontSizes.thirty};
      font-family: ${fontFamilies.proximaSoft};
      content: '˅';
    }

    #toggle:checked ~ .newsBarSection {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.5s ease-out;
    }

    #toggle:checked ~ .newsBarSection > div > div > .newsBarContainer {
      flex: 0;
    }
  `;

  const newsBarSectionCss = css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-height: ${uc('300px')};
    background-color: ${backgroundColors[backgroundColor]};
    transition: max-height 0.5s ease-in;

    @media (${breakpoints.mobile}) {
      max-height: unset;
      padding: ${uc('20px 20px')};
    }
  `;

  const newsBarLeftSectionCss = css`
    width: 56.5%;
    margin: ${uc('50px 80px 0 100px')};
    color: ${textColor};

    h2 {
      letter-spacing: ${letterSpacings.normal};
    }

    @media (${breakpoints.smallDesktopMax}) {
      margin: ${uc('70px 0 0 70px')};
    }

    @media (${breakpoints.tablet}) {
      width: 100%;
      margin: ${uc('0 50px')};
    }

    @media (${breakpoints.mobile}) {
      margin: ${uc('20px 20px 0')};
    }
  `;

  const newsBarRightSectionCss = css`
    width: ${uc('200px')};
    height: ${uc('200px')};
    margin: ${uc('75px 0 0 60px')};

    @media (${breakpoints.tablet}) {
      display: none;
    }
  `;

  const animationContainerCss = css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: ${uc('275px')};
  `;

  const containerCss = css`
    display: flex;
    flex: 1;
    flex-direction: row;
    height: auto;
    overflow: hidden;
    transition: flex 0.5s ease-in;
  `;

  const buttonCss = css`
    display: flex;
    justify-content: 'inherit';

    button,
    a {
      display: inline-block;
      ${buttonSpacing}
    }

    @media (${breakpoints.smallDesktopMin}) {
      a:first-of-type {
        ${padAnimatedBtn ? `margin-right: ${uc('40px')};` : null}
      }
    }

    @media (${breakpoints.mobile}) {
      display: block;
      flex-wrap: wrap;
      width: 100%;

      button,
      a {
        display: block;
        width: 100%;
        margin-right: 0;
        text-align: left;
      }
    }
  `;

  const initialState = !!Cookies.get('saba-cookie');
  const [state, setState] = useState({ isCollapsed: initialState });

  const toggleCollapse = () => {
    setState({ isCollapsed: !state.isCollapsed });

    /* eslint-disable no-unused-expressions */
    !state.isCollapsed
      ? Cookies.set('saba-cookie', { expires: 365 })
      : Cookies.remove('saba-cookie');
  };

  return (
    <div css={newsBarWrapper}>
      <NewsBarToggle isChecked={state.isCollapsed} toggle={toggleCollapse} />
      <Section className="newsBarSection" css={newsBarSectionCss}>
        <PageWrapper>
          <div css={animationContainerCss}>
            <div css={containerCss} className="newsBarContainer">
              <div css={newsBarLeftSectionCss}>
                <Heading2 fontSize={fontSizes.twentyFive} color={colors.white}>
                  {heading}
                </Heading2>
                <Text fontSize={fontSizes.fourteen} color={colors.white}>
                  {description}
                </Text>
                <div>
                  {buttons.length > 0
                    ? renderButtons(buttons, buttonCss)
                    : null}
                </div>
              </div>
              <div css={newsBarRightSectionCss}>
                <Image
                  src={sanityImage(notificationBarImage)
                    .auto('format')
                    .fit('max')
                    .width(500)}
                />
              </div>
            </div>
          </div>
        </PageWrapper>
      </Section>
    </div>
  );
};

NewsBarLarge.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  buttons: PropTypes.arrayOf(PropTypes.shape([])),
  closeText: PropTypes.string,
  description: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  notificationBarImage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({}),
  ]).isRequired,
};

NewsBarLarge.defaultProps = {
  buttons: null,
  closeText: 'close',
};

export default NewsBarLarge;

import PropTypes from 'prop-types';
/** @jsx jsx needed to get Storybook to render */
import { css, jsx } from '@emotion/core';

import {
  breakpoints,
  colors,
  fontFamilies,
  fontWeights,
  lineHeights,
  unitConverter as uc,
} from '../../../../styles/base';
import checkmark from '../../../molecules/Form/assets/check-mark.svg';

export const checkboxCss = css`
  top: 0;
  width: ${uc('40px')};
  max-width: none;
  height: ${uc('40px')};
  min-height: 0;
  margin: 0;
  padding: 0;
  overflow: visible;
  vertical-align: bottom;
  border: ${uc('2px')} solid ${colors.lightGray.two};
  border-radius: ${uc('6px')};
  outline: none;
  cursor: pointer;
  appearance: none;

  &:focus {
    border-width: ${uc('2px')};
  }

  &:checked {
    background-color: ${colors.white};
    background-image: url(${checkmark});
    background-repeat: no-repeat;
    background-size: cover;
    border-color: ${colors.transparent};
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.9;
  }

  & + label {
    cursor: pointer;
  }

  &:disabled + label {
    cursor: not-allowed;
  }
`;

const CheckBox = ({
  marketoFieldName,
  optional,
  register,
  textBlock,
  textLabel,
}) => {
  const formName = marketoFieldName || textLabel;

  const spacerCss = css`
    position: relative;
    display: inline-block;
    min-width: ${uc('10px')};
    height: ${uc('30px')};
    overflow: visible;

    &[required]::after {
      position: absolute;
      top: 0;
      right: ${uc('-7px')};
      color: ${colors.accent};
      line-height: ${lineHeights.title};
      content: '*';
    }
  `;

  const containerCss = css`
    display: flex;
    align-items: flex-start;
    width: 100%;
    max-width: 100%;
    margin-bottom: ${uc('20px')};

    @media (${breakpoints.tablet}) {
      width: ${uc('400px')};
    }
  `;

  const labelCss = css`
    display: block;
    max-width: 84%;
    margin: ${uc('-5px 0 0 8px')};
    padding-left: ${uc('15px')};
    color: ${colors.darkGray.two};
    font-weight: ${fontWeights.bold};
    font-family: ${fontFamilies.proximaSoft};
    line-height: ${uc('32px')};
    white-space: pre-wrap;
    text-align: left;
    text-indent: ${uc('-15px')};
  `;
  return (
    <div css={containerCss}>
      <label css={labelCss} htmlFor="checkbox">
        <input
          type="checkbox"
          id="checkbox"
          name={formName}
          ref={register({ required: !optional })}
          css={checkboxCss}
        />
        <div css={spacerCss} required={!optional} />
        {textBlock.description}
      </label>
    </div>
  );
};

CheckBox.propTypes = {
  marketoFieldName: PropTypes.string,
  optional: PropTypes.bool,
  register: PropTypes.func.isRequired,
  textBlock: PropTypes.instanceOf(Object),
  textLabel: PropTypes.string,
};

CheckBox.defaultProps = {
  marketoFieldName: '',
  optional: false,
  textBlock: {},
  textLabel: '',
};

export default CheckBox;

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';
import { useForm } from 'react-hook-form/dist/react-hook-form.ie11';
import { navigate } from 'gatsby';

import Section from '../Section/Section';
import { Heading2 } from '../../atoms/Headings/Headings';
import Button from '../../atoms/Buttons/Button/Button';
import InputFormField from '../../atoms/FormFields/InputFormField/InputFormField';
import DropdownFormField from '../../atoms/FormFields/DropdownFormField/DropdownFormField';
import CountryFormField from '../../atoms/FormFields/CountryFormField/CountryFormField';
import TextAreaFormField from '../../atoms/FormFields/TextAreaFormField/TextAreaFormField';
import CheckBox from '../../atoms/FormFields/CheckBox/CheckBox';
import GDPREmailFormField from '../../atoms/FormFields/GDPREmailFormField/GDPREmailFormField';

import sanityClient from '../../../utils/sanityClient';

import {
  breakpoints,
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  shadows,
  unitConverter as uc,
} from '../../../styles/base';
import { getQueryStringParams } from '../../../utils/browserUtils';
import checkmark from './assets/check-mark.svg';
import { LocaleContext } from '../../../context/LocaleContext';

const encode = data =>
  Object.keys(data)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');

const Form = ({
  action,
  className,
  formFields,
  formType,
  name,
  onSubmit,
  requiredInstruction,
  sectionStyles,
  sfid,
  submitButton,
  title,
}) => {
  const { locale } = useContext(LocaleContext);
  const localePrefix = locale === 'us' ? '' : `/${locale}`;
  const marketoRouting = {
    contactUs: 'Web - Contact Us',
    demo: 'Web - Demo Request',
    footer: 'Web - Email Subscription',
    letsTalk: 'Web - Lets Talk',
    marketplace: 'Web - Partner Marketplace',
    resources: 'Web - Resource Gallery',
  };

  // hidden fields
  // TODO: update responseMail according to dataset
  const responseMail = ' web-default-us';
  const munchkinId = '076-PDR-714';
  const marketplaceCampaignID = '701000000006VZ7';
  const formID = formType === 'footer' ? '2398' : '';
  const originalSfid =
    formType === 'marketplace' ? marketplaceCampaignID : sfid;
  const [dataSource, setDataSource] = useState(marketoRouting[formType]);
  const [partnerId, setPartnerId] = useState(null);

  // also known as salesforce ID/sfid
  const [campaignID, setCampaignID] = useState(originalSfid);
  let extraCss = false;
  // change campaignID if selected option in dropdown has an SFID
  const handleChange = sfidFromDropdown => {
    // query param wins
    const queryParams = getQueryStringParams(window.location.search);
    if (queryParams.sfid || queryParams.id) return false;

    /*
      if an option is selected with no SFID,
      set campaignID back to value from form
    */
    if (sfidFromDropdown === 'reset') {
      setCampaignID(originalSfid);
    } else {
      // set campaignID to SFID from dropdown option
      setCampaignID(sfidFromDropdown);
    }

    // needs to return something for eslint
    return false;
  };

  // sfid or id GET param trumps campaignID from Sanity
  useEffect(() => {
    const queryParams = getQueryStringParams(window.location.search);
    setCampaignID(queryParams.sfid || queryParams.id || campaignID);
    if (formType === 'marketplace' && queryParams.partner) {
      setDataSource(`${dataSource} - ${queryParams.partner}`);

      sanityClient
        .fetch(
          `*[ _type == "partner" && name == "${queryParams.partner}" ] { partnerId }`
        )
        .then(partners => {
          if (partners && partners.length > 0) {
            setPartnerId(partners[0].partnerId);
          }
        });
    }
  }, []);

  const { errors, handleSubmit, register } = useForm({
    submitFocusError: true,
  });

  const onSubmitFormHook = (data, e) => {
    e.preventDefault();
    const form = e.target;
    if (Object.keys(errors).length > 0) return;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        ...data,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch();
  };

  if (formType === 'footer') {
    extraCss = css`
      text-align: center;

      button {
        padding: ${uc('10px 20px')};
        color: ${colors.white};
        font-size: ${fontSizes.eighteen};
        vertical-align: top;
      }

      ${Heading2} {
        margin-bottom: ${uc('16px')};
        color: ${colors.white};
        font-size: ${fontSizes.twenty};
        letter-spacing: normal;
      }

      .requiredInstruction {
        display: none;
      }

      .errorMessage {
        margin-left: ${uc('20px')};
        color: ${colors.accent};
        text-align: left;

        @media (${breakpoints.tablet}) {
          margin-left: ${uc('100px')};
        }

        @media (${breakpoints.mobile}) {
          margin-left: 0;
        }
      }

      label {
        display: inline-block;
        min-width: ${uc('300px')};
        margin: ${uc('0 10px 20px 0')};
        vertical-align: top;

        span.input-label {
          display: none;
        }

        input {
          width: 100%;
          min-height: ${uc('41px')};
          padding: ${uc('10px')};
          color: ${colors.white};
          vertical-align: bottom;
          background: transparent;
          border: none;
          border-bottom: ${uc('3px')} solid ${colors.white};
          border-radius: 0;

          &:active,
          &:focus,
          &:hover,
          &:invalid:focus {
            border-bottom: ${uc('3px')} solid ${colors.white};
          }

          &::placeholder {
            color: ${colors.lightBlue};
            font-weight: ${fontWeights.bold};
            font-size: ${fontSizes.eighteen};
            opacity: 1;
          }
        }

        .input-error {
          border-bottom: ${uc('3px')} solid
            ${Object.keys(errors).length > 0 ? colors.accent : colors.white};
          border-radius: ${uc('10px')};

          &:active,
          &:focus,
          &:hover,
          &:invalid:focus {
            border-bottom: ${uc('3px')} solid ${colors.accent};
          }
        }

        @media (${breakpoints.largeTablet}) {
          text-align: left;
        }

        @media (${breakpoints.tablet}) {
          padding-top: 0;
        }

        @media (${breakpoints.mobile}) {
          min-width: 100%;
          text-align: center;

          input::placeholder {
            font-size: ${fontSizes.sixteen};
          }
        }
      }
    `;
  }

  const formSectionCss = css`
    margin: ${uc('auto 20px 20px')};
    padding: ${uc('30px 30px 1px 50px')};
    font-size: ${fontSizes.twenty};
    font-family: ${fontFamilies.proximaSoft};
    text-align: center;
    background: ${colors.white};
    border-radius: ${uc('50px')};
    box-shadow: ${shadows.large};

    .requiredInstruction {
      color: ${colors.accent};
      text-align: left;
    }

    .errorMessage {
      color: ${colors.accent};
      font-size: ${fontSizes.sixteen};
      text-align: center;
    }

    @media (${breakpoints.largeTablet}) {
      margin-left: ${uc('20px')};
      padding: ${uc('30px 30px 1px')};
    }

    @media (${breakpoints.tablet}) {
      margin-left: 0;
    }

    @media (${breakpoints.mobile}) {
      margin: ${uc('auto 20px')};
      padding: ${uc('20px')};
    }
  `;

  const formFieldCss = css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: start;
    margin: ${uc('15px auto')};
    color: ${colors.darkGray.two};
    font-weight: ${fontWeights.bold};
    font-family: ${fontFamilies.proximaSoft};

    select {
      font: inherit;
      line-height: ${uc('21px')};
      background-color: ${colors.lightGray.one};
      background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 72.22 42.46' style='enable-background:new 0 0 72.22 42.46;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%234E5969;%7D%0A%3C/style%3E%3Cpath class='st0' d='M35.38,42.36c0.25,0.06,0.49,0.11,0.74,0.11c0.25,0,0.48-0.05,0.73-0.11c0.24-0.06,0.48-0.11,0.71-0.23 c0.24-0.12,0.46-0.28,0.68-0.46c0.14-0.11,0.3-0.16,0.43-0.29L70.88,8.13c1.63-1.68,1.79-4.6,0.38-6.53 c-1.42-1.93-3.88-2.13-5.51-0.46L36.11,31.75L6.46,1.14C4.83-0.54,2.37-0.34,0.96,1.59C0.32,2.47,0,3.55,0,4.63 c0,1.29,0.45,2.58,1.34,3.5l32.21,33.25c0.13,0.14,0.29,0.18,0.43,0.29c0.22,0.18,0.44,0.35,0.68,0.46 C34.9,42.24,35.13,42.3,35.38,42.36z'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: 93% center;
      background-size: 4%;
      appearance: none;
    }

    input {
      background: ${colors.lightGray.one};
    }

    select,
    input {
      align-self: normal;
      box-sizing: border-box;
      width: ${uc('400px')};
      min-height: ${uc('50px')};

      padding: ${uc('0 35px')};
      color: ${colors.darkGray.two};
      border: ${uc('1px')} solid ${colors.transparent};
      border-radius: ${uc('10px')};

      &:focus,
      &:invalid:focus {
        border: ${uc('1px')} solid ${colors.primary};
        outline: none;
      }

      @media (${breakpoints.mobile}) {
        width: 100%;
      }
    }

    select:not(:focus):invalid {
      color: ${colors.lightGray.four};
    }

    &[required] span::after,
    .required-asterisk::after {
      color: ${colors.accent};
      content: ' *';
    }

    .form-checkbox {
      position: relative;
      display: inline-block;
      width: ${uc('40px')};
      max-width: none;
      height: ${uc('40px')};
      min-height: 0;
      padding: 0;
      border: ${uc('2px')} solid ${colors.lightGray.two};
      border-radius: ${uc('6px')};
      appearance: none;

      &:focus {
        border-width: ${uc('2px')};
      }

      &:checked {
        background-color: ${colors.white};
        background-image: url(${checkmark});
        background-repeat: no-repeat;
        background-size: cover;
        border-color: ${colors.transparent};
      }
    }

    label[for='edit-emeaconsentcookiesubmitted'],
    label[for='edit-emailpreferences'] {
      display: inline-block;
      max-width: 84%;
      margin: ${uc('-3px auto 0 10px')};
      line-height: ${uc('30px')};
      text-align: left;

      p {
        display: inline;
      }
    }

    .required {
      color: ${colors.accent};
    }

    span {
      margin: ${uc('5px 10px 10px')};
    }

    @media (${breakpoints.tablet}) {
      flex-flow: column;
      align-items: baseline;
      margin-bottom: ${uc('20px')};

      select,
      input {
        padding: ${uc('15px')};
      }
    }

    @media (${breakpoints.mobile}) {
      font-size: ${fontSizes.eighteen};

      label[for='edit-emeaconsentcookiesubmitted'],
      label[for='edit-emailpreferences'] {
        max-width: 78%;
      }
    }

    .input-error {
      border: solid ${uc('3px')} ${colors.accent};

      &:focus {
        border: solid ${uc('3px')} ${colors.accent};
      }
    }
  `;

  const renderedFormFields =
    formFields.length > 0
      ? formFields.map(formField => {
          const key = formField._key || formField._id;
          if (formField._type === 'reusableFormField') {
            if (
              formField.formFieldReference._type ===
              'dropdownFormFieldReference'
            ) {
              return (
                <DropdownFormField
                  formFieldCss={formFieldCss}
                  key={key}
                  onChange={handleChange}
                  register={register}
                  errors={errors}
                  {...formField.formFieldReference}
                />
              );
            }
            if (
              formField.formFieldReference._type === 'countryFormFieldReference'
            ) {
              return (
                <CountryFormField
                  formFieldCss={formFieldCss}
                  key={key}
                  register={register}
                  errors={errors}
                  {...formField.formFieldReference}
                />
              );
            }
            if (
              formField.formFieldReference._type ===
              'textAreaFormFieldReference'
            ) {
              return (
                <TextAreaFormField
                  formFieldCss={formFieldCss}
                  key={key}
                  register={register}
                  errors={errors}
                  {...formField.formFieldReference}
                />
              );
            }
            if (
              formField.formFieldReference._type ===
              'checkBoxFormFieldReference'
            ) {
              return (
                <CheckBox
                  key={key}
                  register={register}
                  errors={errors}
                  {...formField.formFieldReference}
                />
              );
            }

            return (
              <InputFormField
                formFieldCss={formFieldCss}
                key={key}
                register={register}
                errors={errors}
                {...formField.formFieldReference}
              />
            );
          }
          if (formField._type === 'dropdown') {
            return (
              <DropdownFormField
                formFieldCss={formFieldCss}
                key={key}
                onChange={handleChange}
                register={register}
                errors={errors}
                {...formField}
              />
            );
          }
          if (formField._type === 'gdprEmailFormFieldReference') {
            return (
              <GDPREmailFormField
                formFieldCss={formFieldCss}
                key={key}
                register={register}
                errors={errors}
                {...formField}
              />
            );
          }
          return (
            <InputFormField
              formFieldCss={formFieldCss}
              key={key}
              register={register}
              errors={errors}
              {...formField}
            />
          );
        })
      : null;

  const formProps =
    typeof onSubmit === 'function'
      ? { onSubmit: handleSubmit(onSubmit) }
      : {
          action:
            action && action.slug && `${localePrefix}${action.slug.current}`,
          onSubmit: handleSubmit(onSubmitFormHook),
        };

  return (
    <Section
      sectionStyles={sectionStyles}
      css={extraCss || formSectionCss}
      key={`form-${name}`}
      className={className}
    >
      {title && <Heading2>{title}</Heading2>}
      <form
        name={name}
        method="POST"
        {...formProps}
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value={name} ref={register} />
        <input
          type="hidden"
          name="campaignID"
          value={campaignID}
          ref={register}
        />
        <input
          type="hidden"
          name="response_mail"
          value={responseMail}
          ref={register}
        />
        <input
          type="hidden"
          name="munchkinId"
          value={munchkinId}
          ref={register}
        />
        <input
          type="hidden"
          name="Data_Source__c"
          value={dataSource}
          ref={register}
        />
        <input
          type="hidden"
          name="Interest__c"
          value={dataSource}
          ref={register}
        />
        <input type="hidden" name="_mkt_disp" value="return" ref={register} />
        <input type="hidden" name="_mkt_trk" value="" ref={register} />
        <input type="hidden" name="form_id" value={formID} ref={register} />
        <input
          type="hidden"
          name="Co_Sponsoring_Account__c"
          value={partnerId}
          ref={register}
        />
        {renderedFormFields}
        <p className="requiredInstruction">* {requiredInstruction}</p>
        <Button
          _key={`${name}-submit-button`}
          type="submit"
          color={submitButton.color}
          outline={submitButton.buttonOutline}
        >
          {submitButton.text}
        </Button>
        {Object.keys(errors).length > 0 && (
          <p className="errorMessage">One or more fields are invalid</p>
        )}
      </form>
    </Section>
  );
};

Form.propTypes = {
  action: PropTypes.shape({ slug: PropTypes.instanceOf(Object) }).isRequired,
  className: PropTypes.string,
  formFields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  formType: PropTypes.string,
  name: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
  requiredInstruction: PropTypes.string,
  sectionStyles: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({}),
  ]),
  sfid: PropTypes.string,
  submitButton: PropTypes.instanceOf(Object).isRequired,
  title: PropTypes.string,
};

Form.defaultProps = {
  className: '',
  formType: '',
  onSubmit: null,
  requiredInstruction: 'Indicates required field',
  sectionStyles: {},
  sfid: '701j0000001QfoS',
  title: '',
};

export default Form;

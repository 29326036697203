import PropTypes from 'prop-types';
/** @jsx jsx needed to get Storybook to render */
import { css, jsx } from '@emotion/core';
import {
  breakpoints,
  buttonColors,
  fontFamilies,
  fontWeights,
  lineHeights,
  shadows,
  unitConverter as uc,
} from '../../../../styles/base';
import Link from '../../Link/Link';

export const generateButtonCss = (color, outline, shadow) => {
  return css`
    display: inline-block;
    margin-right: ${uc('10px')};
    margin-bottom: ${uc('20px')};
    padding: ${uc('12px 20px')};
    overflow: hidden;
    color: ${outline
      ? buttonColors[color].background
      : buttonColors[color].color};
    font-weight: ${fontWeights.semiBold};
    font-family: ${fontFamilies.proximaSoft};
    line-height: ${lineHeights.title};
    letter-spacing: ${uc('0.5px')};
    text-transform: lowercase;
    text-decoration: none;

    background: ${outline ? 'transparent' : buttonColors[color].background};
    border: ${outline ? `${uc('2px')} solid` : `none`};
    border-color: ${buttonColors[color].background};
    border-radius: ${uc('14px')};
    ${shadow ? `box-shadow: ${shadows.large};` : ''}

    cursor: pointer;
    transition: 0.3s;

    &:active,
    &:focus,
    &:hover {
      color: ${buttonColors[color].colorHover};
      background: ${buttonColors[color].backgroundHover};
      border-color: ${buttonColors[color].backgroundHover};
    }

    @media (${breakpoints.mobile}) {
      display: block;
      width: 100%;
      margin-right: 0;
    }
  `;
};

const Button = ({
  _key,
  children,
  className,
  color,
  link,
  outline,
  shadow,
  size,
  ...props
}) => {
  let buttonID = `button-id-${_key}`;
  if (!_key && className === 'video-button') {
    buttonID = 'video-button';
  }

  if (link) {
    return (
      <Link
        type="button"
        css={generateButtonCss(color, outline, shadow)}
        to={link}
        className={[buttonID, className].join(' ')}
        {...props}
      >
        {children}
      </Link>
    );
  }
  return (
    <button
      type="button"
      css={generateButtonCss(color, outline, shadow)}
      className={[buttonID, className].join(' ')}
      {...props}
    >
      {children}
    </button>
  );
};

// props
// default of outline is solid
Button.propTypes = {
  _key: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
  link: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.shape({})),
  ]),
  outline: PropTypes.bool,
  shadow: PropTypes.bool,
  size: PropTypes.string,
};

// default props
Button.defaultProps = {
  _key: '',
  className: '',
  color: 'gray',
  link: false,
  outline: false,
  shadow: false,
  size: 'med',
};

export default Button;

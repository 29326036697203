import PropTypes from 'prop-types';
/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';
import CsodBlockContent from '../../../molecules/BlockContent/BlockContent';
import {
  breakpoints,
  colors,
  fontSizes,
  unitConverter as uc,
} from '../../../../styles/base';

const checkboxLabelCss = css`
  color: white;

  label.option {
    display: flex;
    align-items: center;
    max-width: ${uc('340px')};
    padding-left: ${uc('20px')};

    input[type='checkbox'] {
      width: auto;
    }

    p {
      margin-bottom: 0;
      margin-left: ${uc('10px')};
      font-size: ${fontSizes.twelve};
      text-align: left;
    }

    a {
      color: ${colors.white};
      text-decoration: underline;
    }

    @media (${breakpoints.mobile}) {
      padding-left: 0;
    }
  }
`;

const GDPRCheckbox = ({ gdprText }) => (
  <div css={checkboxLabelCss}>
    <label className="option" htmlFor="edit-emeaconsentcookiesubmitted">
      <input
        type="checkbox"
        id="edit-emeaconsentcookiesubmitted"
        name="eMEAconsentcookiesubmitted"
        value="yes"
        className="form-checkbox required"
        required
      />
      <CsodBlockContent blocks={gdprText} />
      <span className="required-asterisk" />
    </label>
  </div>
);

GDPRCheckbox.propTypes = {
  gdprText: PropTypes.instanceOf(Object),
};

GDPRCheckbox.defaultProps = {
  gdprText: null,
};

export default GDPRCheckbox;

import hexRgb from 'hex-rgb';

/**
 *
 * @param unit --  either px or rem. ex('16px', '1rem')
 * @returns output -- outputs equivalent of other unit.
 * ex('16px' outputs '1rem' ,'1rem' outputs '16px')
 */
export const unitConverter = (unit, base) => {
  const baseFontSize = base || 16;

  const units = unit.split(' ');
  const convertedUnits = [];

  units.forEach(currentUnit => {
    const unitParts = currentUnit.match(/^(-?\d*(?:\.\d+)?)?(.+?)$/);
    const value = unitParts[1];
    const type = unitParts[2];
    let output = false;

    switch (type) {
      case 'px':
        output = `${parseFloat(value / baseFontSize)}rem`;
        break;
      case 'rem':
        output = `${parseFloat(value * baseFontSize)}px`;
        break;
      default:
        output = currentUnit;
        break;
    }

    if (output) {
      convertedUnits.push(output);
    }
  });

  return convertedUnits.join(' ');
};

/**
 *
 * @param hex
 * @param alpha
 * @returns {string} formatted for CSS
 */
export const rgba = (hex, alpha) => {
  const rgb = hexRgb(hex, { format: 'array' })
    .slice(0, -1)
    .join(',');
  return `${rgb},${alpha}`;
};

export const pageDimensions = {
  mobileWidth: unitConverter('480px'),
  tabletWidth: unitConverter('768px'),
  largeTabletWidth: unitConverter('1080px'),
  smallDesktopWidth: unitConverter('1280px'),
  smallDesktopHeight: unitConverter('800px'), // adam's laptop height
  desktopWidth: unitConverter('1390px'),
  largeDesktopWidth: unitConverter('1920px'),
  smallLaptopHeight: unitConverter('800px'),
  smallLaptopWidth: unitConverter('1280px'),
  rawMobileWidth: 480,
  rawTabletWidth: 768,
  rawDesktopWidth: 1390, // needed for media query calculations
  rawLargeDesktopWidth: 1920, // needed for sanity's width function
};

export const breakpoints = {
  mobile: `max-width: ${pageDimensions.mobileWidth}`,
  tablet: `max-width: ${pageDimensions.tabletWidth}`,
  largeTablet: `max-width: ${pageDimensions.largeTabletWidth}`,
  smallDesktopMin: `min-width: ${pageDimensions.smallDesktopWidth}`,
  smallDesktopMax: `max-width: ${pageDimensions.smallDesktopWidth}`,
  smallDesktopHeight: `max-height: ${pageDimensions.smallDesktopHeight}`,
  desktop: `max-width: ${pageDimensions.desktopWidth}`,
  largeDesktop: `min-width: ${pageDimensions.largeDesktopWidth}`,
  mdpiLaptop: `min-width: ${pageDimensions.smallDesktopWidth}) 
  and (max-height: ${pageDimensions.smallDesktopHeight}`,
};

export const menuBreakpoints = {
  collapseCompanyMenu: `max-width: ${unitConverter('1227px')}`,
  collapseCommunityMenu: `max-width: ${unitConverter('1139px')}`,
  completeSwitchToMobile: `max-width: ${unitConverter('980px')}`,
};

export const decathalonBreakpoints = {
  mobile: `max-width: ${unitConverter('750px')}`,
  tablet: `max-width: ${unitConverter('950px')}`,
};

export const arrowSymbol = '→';

export const colors = {
  primary: '#0047ba', // blue
  primaryHover: '#0054DD', // lighter blue
  accent: '#F26178', // koral
  accentHover: '#E14864', // lighter koral
  darkBlue: '#072658',
  lightBlue: '#7ca7ed',
  blueBlack: '#303746',
  transparent: 'transparent',
  lightGray: {
    one: '#F1F3F4',
    two: '#D5DADE',
    three: '#ACB6BE',
    four: '#919DA8',
    five: '#758592',
    six: '#f5f9fc',
    seven: '#98aab8',
    eight: '#F4F8FB',
  },
  mediumGray: '#748592',
  darkGray: {
    one: '#63748C',
    two: '#475167',
    three: '#2F3944',
  },
  black: '#000000',
  white: '#FFFFFF',
  // suite colors
  recruiting: {
    // orange
    main: '#ff4d00',
    hover: '#993921',
    avatar: {
      primary: '#993921',
      accent: '#ff4d00',
    },
  },
  learning: {
    // light blue
    main: '#3cb4e5',
    hover: '#006098',
    avatar: {
      primary: '#006098',
      accent: '#3cb4e5',
    },
    gradient: {
      start: '#33aee4',
      end: '#036edd',
    },
  },
  development: {
    // light blue
    main: '#3cb4e5',
    hover: '#006098',
    avatar: {
      primary: '#006098',
      accent: '#3cb4e5',
    },
    gradient: {
      start: '#33aee4',
      end: '#036edd',
    },
  },
  careers: {
    // green
    main: '#6abf4b',
    hover: '#006937',
    avatar: {
      primary: '#006937',
      accent: '#6abf4b',
    },
    gradient: {
      start: '#82c760',
      end: '#167039',
    },
  },
  performance: {
    // green
    main: '#6abf4b',
    hover: '#006937',
    avatar: {
      primary: '#006937',
      accent: '#6abf4b',
    },
    gradient: {
      start: '#82c760',
      end: '#167039',
    },
  },
  hr: {
    // purple
    main: '#cc5599',
    hover: '#7F2854',
    avatar: {
      primary: '#7F2854',
      accent: '#cc5599',
    },
    gradient: {
      start: '#cb56a0',
      end: '#812a57',
    },
  },
  contentAnytime: {
    // picton blue
    main: '#0047ba',
    hover: '#013790',
    avatar: {
      primary: '#013790',
      accent: '#15629a',
    },
  },
  marigold: '#fdb14a',
  mint: '#6fd6af',
  mintHover: '#1EB096',
  awardHeading: '#eec643',
  seafoam: '#3eb3e3', // seafoam is back
};

export const headingColors = {
  mediumGray: colors.mediumGray,
  darkGray: colors.darkGray.three,
  gray: colors.lightGray.five,
  white: colors.white,
  primary: colors.primary,
  black: colors.darkGray.four,
  blueBlack: colors.blueBlack,
};

export const backgroundColors = {
  darkGray: colors.darkGray.three,
  primary: colors.primary,
  accent: colors.accent,
  darkBlue: colors.darkBlue,
};

export const suiteColors = {
  recruiting: colors.recruiting.main,
  learning: colors.learning.main,
  performance: colors.performance.main,
  hr: colors.hr.main,
  contentAnytime: colors.contentAnytime.main,
};

export const menuColors = {
  gray: colors.mediumGray,
  white: colors.white,
  primary: colors.primary,
  darkGray: colors.darkGray.three,
  whiteHover: colors.seafoam,
  transparent: colors.transparent,
};

export const buttonColors = {
  gray: {
    background: colors.darkGray.two,
    backgroundHover: colors.darkGray.one,
    color: colors.white,
    colorHover: colors.white,
  },
  accent: {
    background: colors.accent,
    backgroundHover: colors.accentHover,
    color: colors.white,
    colorHover: colors.white,
  },
  primary: {
    background: colors.primary,
    backgroundHover: colors.primaryHover,
    color: colors.white,
    colorHover: colors.white,
  },
  lightGray: {
    background: colors.lightGray.one,
    backgroundHover: colors.lightGray.one,
    color: colors.accent,
    colorHover: colors.accentHover,
  },
  white: {
    background: colors.white,
    backgroundHover: colors.lightGray.one,
    color: colors.darkGray.two,
    colorHover: colors.darkGray.three,
  },
  mint: {
    background: colors.mint,
    backgroundHover: colors.mintHover,
    color: colors.white,
    colorHover: colors.white,
  },
  darkBlue: {
    background: '#002a7b',
    backgroundHover: '#00205e',
    color: colors.white,
    colorHover: colors.white,
  },
};

// add spacing when two buttons are side by side
export const buttonSpacing = {
  marginRight: unitConverter('10px'),
};

export const fontFamilies = {
  roboto: 'Roboto, sans-serif',
  proximaSoft: 'proxima-soft, sans-serif',
};

export const fontSizes = {
  twelve: unitConverter('12px'),
  fourteen: unitConverter('14px'),
  fifteen: unitConverter('15px'),
  sixteen: unitConverter('16px'),
  seventeen: unitConverter('17px'),
  eighteen: unitConverter('18px'),
  twenty: unitConverter('20px'),
  twentyTwo: unitConverter('22px'),
  twentyThree: unitConverter('23px'),
  twentyFive: unitConverter('25px'),
  twentyEight: unitConverter('28px'),
  thirty: unitConverter('30px'),
  thirtyTwo: unitConverter('32px'),
  thirtyEight: unitConverter('38px'),
  forty: unitConverter('40px'),
  fourtySix: unitConverter('46px'),
  fifty: unitConverter('50px'),
  sixty: unitConverter('60px'),
};

export const fontWeights = {
  thin: '100',
  extraLight: '200',
  light: '300',
  normal: '400',
  medium: '500',
  semiBold: '600',
  bold: '700',
  extraBold: '800',
  black: '900',
};

export const durations = {
  fast: '100ms',
  medium: '250ms',
  slow: '500ms',
  verySlow: '1000ms',
};

export const lineHeights = {
  solid: 1,
  title: 1.25,
  copy: 1.5,
};

export const letterSpacings = {
  normal: 'normal',
  tracked: '0.1em',
  tight: '-0.05em',
  mega: '0.25em',
};

export const shadows = {
  small: `${unitConverter('0 0 24px')} rgba(0,0,0,.125)`,
  // smallHovered uses 2 box-shadows to give a richer, more realistic shadow.
  // the first one is tighter, and the second one is wider
  smallHovered: `${unitConverter('0 0 14px')} rgba(0,0,0,.125), ${unitConverter(
    '0 0 34px'
  )} rgba(0,0,0,.125)`,
  large: `${unitConverter('1px 10px 25px')} rgba(0,0,0,.125)`,
  largeHovered: `${unitConverter('1px 10px 35px')} rgba(0,0,0,.225)`,
  largeActive: `${unitConverter(
    '1px 10px 15px'
  )} rgba(0,0,0,.3), ${unitConverter('1px 10px 35px')} rgba(0,0,0,.3)`,
  smallText: `${unitConverter('0 0 10px')} ${colors.black}`,
};

export const csodAngle = {
  degrees: 11,
  angleHeight(width) {
    return Math.tan(this.degrees * (Math.PI / 180)) * width;
  },
  angleWidth(height) {
    return Math.tan(this.degrees * (Math.PI / 180)) * (1 / height);
  },
  heightPercent(width) {
    return (this.angleHeight(width) / width) * 100;
  },
  widthPercent(height) {
    return (this.angleWidth(height) / height) * 100;
  },
};

export const sectionSettings = {
  none: '0',
  nano: unitConverter('10px'),
  micro: unitConverter('20px'),
  xsmall: unitConverter('30px'),
  small: unitConverter('60px'),
  medium: unitConverter('90px'),
  large: unitConverter('110px'),
  xlarge: unitConverter('140px'),
  xxlarge: unitConverter('170px'),
  lightGray: colors.lightGray.one,
  darkGray: colors.darkGray.three,
  primary: colors.primary,
  white: colors.white,
  transparent: 'transparent',
};

export default {
  unitConverter,
  breakpoints,
  csodAngle,
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
  letterSpacings,
  shadows,
  durations,
  sectionSettings,
};

import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { colors, fontSizes, fontFamilies } from '../../../../styles/base';

const Heading5 = styled.h5`
  color: ${props => props.color};
  font-size: ${props => props.fontSize};
  font-family: ${fontFamilies.proximaSoft};
`;

Heading5.propTypes = {
  color: PropTypes.string,
  fontSize: PropTypes.string,
};

Heading5.defaultProps = {
  color: colors.darkGray.three,
  fontSize: fontSizes.sixteen,
};

export default Heading5;

import { Fragment } from 'react';
import PropTypes from 'prop-types';
/** @jsx jsx this comment is required for Storybook to render */
import { jsx } from '@emotion/core';

const NewsBarToggle = ({ isChecked, toggle }) => {
  return (
    <Fragment>
      <input
        checked={isChecked}
        type="checkbox"
        name="toggle"
        id="toggle"
        onChange={e => {
          if (toggle) {
            toggle(e.target.checked);
          }
        }}
      />
      {/* eslint-disable-next-line */}
      <label htmlFor="toggle" />
    </Fragment>
  );
};

NewsBarToggle.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default NewsBarToggle;

import { useEffect, useState } from 'react';
/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';

import InputFormField from '../InputFormField/InputFormField';
import GDPRCheckbox from '../GDPRCheckbox/GDPRCheckbox';

const gdprEmailCss = css`
  display: inline-block;
  vertical-align: top;
`;

// this has dependencies on GDPR.jsx and gdpr.js
const GDPREmailFormField = ({
  _id,
  _type,
  errors,
  formFieldCss,
  gdprText,
  marketoFieldName,
  optional,
  placeholder,
  register,
  textLabel,
}) => {
  const [gdprLocation, setGdprLocation] = useState('in-eu');

  useEffect(() => {
    let count = 0;
    const gdprInterval = setInterval(() => {
      count += 1;
      if (count > 3) {
        clearInterval(gdprInterval);
      }
      setGdprLocation(localStorage.getItem('gdpr-location'));
    }, 1000);

    return () => clearInterval(gdprInterval);
  }, []);

  return (
    <div css={gdprEmailCss}>
      <InputFormField
        name="Email"
        _id={_id}
        _type={_type}
        errors={errors}
        formFieldCss={formFieldCss}
        marketoFieldName={marketoFieldName}
        optional={optional}
        placeholder={placeholder}
        register={register}
        textLabel={textLabel}
      />
      {gdprLocation === 'in-eu' && (
        <div>
          <GDPRCheckbox gdprText={gdprText} />
        </div>
      )}
    </div>
  );
};

GDPREmailFormField.propTypes = {
  ...GDPRCheckbox.propTypes,
  ...InputFormField.propTypes,
};

GDPREmailFormField.defaultProps = {
  ...GDPRCheckbox.defaultProps,
  ...InputFormField.defaultProps,
};

export default GDPREmailFormField;

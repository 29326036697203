const isObject = require('lodash/fp/isObject');
const { configForDataset } = require('../../../backend/config/intlConfig');
const {
  supportedLanguages: supportedLocales,
} = require('../../../backend/config/languageFilterConfig').default;
const sanityJson = require('../../../backend/sanity.json');

const defaultLocale = supportedLocales.find(l => l.isDefault);

// eslint-disable-next-line import/prefer-default-export
export const getSiteUrlFromRoute = route => {
  const config = configForDataset(sanityJson.api.dataset);
  return `${config.url}${route}`;
};

export const createLocaleTextGetter = localeCode => {
  const locales = [localeCode, defaultLocale.id]; // last language in array is default;
  const localize = value => {
    if (Array.isArray(value)) {
      return value.map(v => localize(v, locales));
    }
    if (isObject(value)) {
      if (/^locale[A-Z]/.test(value._type)) {
        const language = locales.find(lang => value[lang]);
        return value[language];
      }
      return Object.keys(value).reduce(
        (result, key) => ({ ...result, [key]: localize(value[key], locales) }),
        {}
      );
    }
    return value;
  };
  return localize;
};

import { useContext, useMemo } from 'react';
import isObject from 'lodash/fp/isObject';
import getOr from 'lodash/fp/getOr';
import {
  forceTrailingSlash,
  getLocalePrefix,
  shouldAddTrailingSlash,
} from '../utils/urlUtils';
import { LocaleContext } from '../context/LocaleContext';

const useLink = (to, isLocalized) => {
  const { locale } = useContext(LocaleContext);
  const prefix = getLocalePrefix(locale);
  const localizeLink = v => (isLocalized ? `${prefix}${v}` : v);

  const href = useMemo(() => {
    if (typeof to === 'string') {
      const url = localizeLink(to);
      return shouldAddTrailingSlash(url) ? forceTrailingSlash(url) : url;
    }

    if (isObject(to)) {
      const {
        link: [link],
      } = to;

      if (!link) return null;

      if (link.externalLink) return link.externalLink;

      if (link.slug) return forceTrailingSlash(localizeLink(link.slug.current));
      if (link.fileLink) return getOr('', 'fileLink.asset.url', link);
      if (link.phoneNumber) {
        const phone = link.phoneNumber.replace(/[\s().-]/g, '');
        return `tel:${phone}`;
      }
    }

    return null;
  }, [to]);

  return href;
};

export default useLink;

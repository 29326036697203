import PropTypes from 'prop-types';
/** @jsx jsx needed to get Storybook to render */
import { css, jsx } from '@emotion/core';
import sanityImage from '../../../utils/sanityImage';

import {
  colors,
  fontSizes,
  lineHeights,
  unitConverter as uc,
} from '../../../styles/base';
import Quote from '../../molecules/Quote/Quote';

const BlockContentImageQuoteSection = ({ leftColumnImage, ...props }) => {
  const sectionCss = css`
    display: flex;
    height: ${uc('425px')};

    blockquote {
      margin: ${uc('15px 0')};
      color: ${colors.primary};
      font-size: ${fontSizes.thirty};
      font-style: italic;
      line-height: ${lineHeights.copy};
    }

    figure img {
      height: ${uc('150px')};
    }

    figcaption {
      width: ${uc('200px')};
    }

    figcaption div {
      width: 100%;
      margin-bottom: ${uc('10px')};
      font-size: ${fontSizes.seventeen};
    }

    div:last-of-type {
      width: 50%;
    }
  `;

  const leftColumnBackgroundImage = sanityImage(leftColumnImage)
    .auto('format')
    .width(400)
    .fit('max')
    .url();

  const leftColumnImageCss = css`
    width: 45%;
    margin: ${uc('0 30px 45px 5px')};
    background-image: url(${leftColumnBackgroundImage});
    background-size: cover;
  `;

  return (
    <section css={sectionCss}>
      {leftColumnImage && leftColumnImage.asset && (
        <div css={leftColumnImageCss} />
      )}
      <Quote
        orientation="vertical"
        authorTitleColor={colors.lightGray.three}
        {...props}
      />
    </section>
  );
};

BlockContentImageQuoteSection.propTypes = {
  leftColumnImage: PropTypes.shape({ asset: {} }),
};

BlockContentImageQuoteSection.defaultProps = {
  leftColumnImage: null,
};

export default BlockContentImageQuoteSection;

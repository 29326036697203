import PropTypes from 'prop-types';
/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';

import {
  colors,
  fontFamilies,
  fontWeights,
  unitConverter as uc,
} from '../../../../styles/base';

const TextAreaFormField = ({
  _id,
  cols,
  errors,
  formFieldCss,
  marketoFieldName,
  optional,
  placeholder,
  register,
  rows,
  textLabel,
}) => {
  const textAreaCss = css`
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    align-items: center;

    align-self: normal;
    box-sizing: border-box;
    width: ${uc('400px')};
    max-width: 100%;
    min-height: ${uc('50px')};

    padding: ${uc('35px')};
    color: ${colors.darkGray.two};
    font-weight: ${fontWeights.bold};
    font-family: ${fontFamilies.proximaSoft};
    line-height: ${uc('24px')};
    background: ${colors.lightGray.one};
    border: ${uc('1px')} solid ${colors.transparent};
    border-radius: ${uc('10px')};
    resize: none;

    &:focus,
    &:invalid:focus {
      border: ${uc('1px')} solid ${colors.primary};
      outline: none;
    }
  `;

  const fieldName = marketoFieldName || textLabel;
  const fieldId = `textarea_${_id}`;

  return (
    <label css={formFieldCss} htmlFor={fieldId} required={!optional}>
      <span className="input-label">{textLabel}</span>
      <textarea
        css={textAreaCss}
        name={fieldName}
        className={Object.keys(errors).includes(fieldName) ? 'input-error' : ''}
        placeholder={placeholder}
        ref={register({ required: !optional })}
        id={fieldId}
        cols={cols}
        rows={rows}
      />
    </label>
  );
};

TextAreaFormField.propTypes = {
  _id: PropTypes.string.isRequired,
  cols: PropTypes.string,
  errors: PropTypes.shape({}).isRequired,
  formFieldCss: PropTypes.shape({}),
  marketoFieldName: PropTypes.string,
  optional: PropTypes.bool,
  placeholder: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  rows: PropTypes.string,
  textLabel: PropTypes.string.isRequired,
};

TextAreaFormField.defaultProps = {
  cols: '34',
  formFieldCss: {},
  marketoFieldName: '',
  optional: false,
  rows: '5',
};

export default TextAreaFormField;

import PropTypes from 'prop-types';
/** @jsx jsx needed to get Storybook to render */
import { css, jsx } from '@emotion/core';
import { pageDimensions } from '../../../styles/base';

const pageWrapperCss = css`
  width: 100%;
  max-width: ${pageDimensions.desktopWidth};
  margin: auto;
`;

const PageWrapper = ({ children, ...props }) => (
  <div css={pageWrapperCss} {...props}>
    {children}
  </div>
);

PageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageWrapper;

/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
/** @jsx jsx this comment is required for Storybook to render */
import BlockContent from '@sanity/block-content-to-react';
import { css, jsx } from '@emotion/core';

import {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  Heading6,
} from '../../atoms/Headings/Headings';
import Table from '../Table/Table';
import Quote from '../Quote/Quote';
import sanityImage from '../../../utils/sanityImage';
import BlockContentImageQuoteSection from '../../organisms/BlockContentImageQuoteSection/BlockContentImageQuoteSection';
import Image from '../../atoms/Image/Image';
import { breakpoints, colors, fontSizes } from '../../../styles/base';

const CsodBlockContent = ({ blocks, serializers, ...blockContentProps }) => {
  const quoteCss = css`
    blockquote {
      max-width: 100%;
      color: ${colors.primary};
      font-size: ${fontSizes.twentyFive};
      font-style: italic;

      @media (${breakpoints.mobile}) {
        font-size: ${fontSizes.eighteen};
      }
    }
  `;

  const Headings = [Heading1, Heading2, Heading3, Heading4, Heading5, Heading6];

  const BlockRenderer = props => {
    const { style = 'normal' } = props.node;

    // checks if block is an h1, h2, h3, etc.
    if (/^h\d/.test(style)) {
      // grabs the number from the h1, h2, h3, etc.
      const level = style.replace(/[^\d]/g, '');
      // selects the correct heading component
      const Heading = Headings[level - 1];
      return <Heading {...props} />;
    }

    // Fall back to default handling
    return BlockContent.defaultSerializers.types.block(props);
  };

  const defaultSerializers = {
    types: {
      block: BlockRenderer,
      table: ({ node: { rows } }) => {
        return <Table rows={rows} />;
      },
      csodImage: ({ _key, node: { asset } }) => (
        <Image
          src={sanityImage(asset)
            .auto('format')
            .url()}
          key={`image-component-${_key}`}
        />
      ),
      quote: ({ _key, node }) => (
        <Quote
          css={quoteCss}
          key={`quote-component-${_key}`}
          drawingWidth={125}
          {...node}
        />
      ),
      blockContentImageQuoteSection: ({ _key, ...props }) => (
        <BlockContentImageQuoteSection
          key={`block-content-image-quote-section${_key}`}
          {...props}
        />
      ),
    },
  };

  const serializer = {
    types: {
      ...defaultSerializers.types,
      ...serializers.types,
    },
    marks: {
      ...defaultSerializers.marks,
      ...serializers.marks,
    },
  };

  return (
    <BlockContent
      blocks={blocks}
      serializers={serializer}
      {...blockContentProps}
    />
  );
};

CsodBlockContent.propTypes = {
  content: PropTypes.arrayOf(PropTypes.shape({})),
  serializers: PropTypes.shape({
    types: PropTypes.shape({}),
    marks: PropTypes.shape({}),
  }),
};

CsodBlockContent.defaultProps = {
  content: [],
  serializers: {
    types: {},
    marks: {},
  },
};

export default CsodBlockContent;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { colors } from '../../../styles/base';
import sanityJson from '../../../../../backend/sanity.json';
import {
  getLocale,
  getEnvironment,
} from '../../../../../backend/config/intlConfig';

const editLinkCss = css`
  display: block;
  padding: 10px;
  color: ${colors.darkGray.three};
  text-align: center;
  text-decoration: underline;
  background: ${colors.mint};

  button {
    position: absolute;
    right: 20px;
  }
`;

const EditPage = ({ sanityId }) => {
  const initialState =
    typeof window !== 'undefined'
      ? window.location.search === '?edit' ||
        localStorage.getItem('editBarEnabled') === 'true'
      : false;

  const [userWantsToEdit, setUserWantsToEdit] = useState(initialState);

  const sanityDataset = sanityJson.api.dataset;
  const locale = getLocale(sanityDataset);
  const sanityEnv = getEnvironment(sanityDataset);
  const sanityUrl = `https://csod-${locale}-sanity-${sanityEnv}.netlify.app`;

  if (userWantsToEdit) {
    localStorage.setItem('editBarEnabled', 'true');
  }

  const hideEditBar = () => {
    setUserWantsToEdit(false);
    localStorage.removeItem('editBarEnabled');
  };

  return (
    userWantsToEdit && (
      <div css={editLinkCss}>
        <a href={`${sanityUrl}/desk/__edit__${sanityId}`}>edit this page</a>
        <button type="button" onClick={hideEditBar}>
          hide
        </button>
      </div>
    )
  );
};

EditPage.propTypes = {
  sanityId: PropTypes.string.isRequired,
};

export default EditPage;

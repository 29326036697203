/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import {
  breakpoints,
  sectionSettings,
  unitConverter as uc,
} from '../../../styles/base';

const Section = ({
  backgroundColor,
  children,
  className,
  paddingBottom,
  paddingTop,
  sectionStyles,
}) => {
  /* TODO: Get default styles to work */

  let marginTop = 'initial';
  if (sectionStyles.marginTop && sectionStyles.marginTopPercentage) {
    marginTop = `${sectionStyles.marginTop}%`;
  } else if (sectionStyles.marginTop) {
    marginTop = uc(`${sectionStyles.marginTop}px`);
  }

  const sectionCss = css`
    position: relative;
    margin-top: ${marginTop};
    margin-bottom: ${sectionStyles.marginBottom
      ? uc(`${sectionStyles.marginBottom}px`)
      : 'initial'};
    padding-top: ${sectionStyles.paddingTop
      ? sectionSettings[sectionStyles.paddingTop]
      : sectionSettings[paddingTop]};
    padding-bottom: ${sectionStyles.paddingBottom
      ? sectionSettings[sectionStyles.paddingBottom]
      : sectionSettings[paddingBottom]};
    background-color: ${sectionStyles.backgroundColor
      ? sectionSettings[sectionStyles.backgroundColor]
      : sectionSettings[backgroundColor]};
    background-position-x: center;
    background-position-y: ${sectionStyles.backgroundPositionY
      ? uc(`${sectionStyles.backgroundPositionY}px`)
      : 0};

    @media (${breakpoints.tablet}) {
      margin-top: ${sectionStyles.marginTop ? uc('15px') : 0};
      margin-bottom: ${sectionStyles.marginBottom ? uc('15px') : 0};
      padding-top: ${uc('30px')};
      padding-bottom: ${sectionStyles.paddingBottom ? uc('15px') : 0};
    }

    &:first-of-type {
      z-index: 1;
    }

    &:nth-of-type(2) {
      z-index: 2;
    }
  `;

  return (
    <section className={className} css={sectionCss}>
      {children}
    </section>
  );
};

Section.propTypes = {
  sectionStyles: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({}),
  ]),
  children: PropTypes.node.isRequired,
  paddingTop: PropTypes.string,
  paddingBottom: PropTypes.string,
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
};

Section.defaultProps = {
  sectionStyles: {},
  paddingTop: sectionSettings.medium,
  paddingBottom: sectionSettings.medium,
  backgroundColor: sectionSettings.transparent,
  className: '',
};

export default Section;

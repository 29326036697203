import PropTypes from 'prop-types';
/** @jsx jsx this comment is required for Storybook to render */
import { jsx } from '@emotion/core';

const InputFormField = ({
  _id,
  _type,
  errors,
  formFieldCss,
  marketoFieldName,
  optional,
  placeholder,
  register,
  textLabel,
}) => {
  let formFieldType = 'text';
  const formName = marketoFieldName || textLabel;
  const registerOptions = {
    required: !optional,
  };
  if (_type === 'emailInputFormFieldReference') {
    formFieldType = 'email';
    // eslint-disable-next-line no-useless-escape
    registerOptions.pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  }

  const fieldId = `field_${_id}`;
  return (
    <label css={formFieldCss} htmlFor={fieldId} required={!optional}>
      <span className="input-label">{textLabel}</span>
      <input
        type={formFieldType}
        name={formName}
        className={Object.keys(errors).includes(formName) ? 'input-error' : ''}
        placeholder={placeholder}
        ref={register(registerOptions)}
        id={fieldId}
      />
    </label>
  );
};

InputFormField.propTypes = {
  _id: PropTypes.string.isRequired,
  _type: PropTypes.string.isRequired,
  errors: PropTypes.shape({}).isRequired,
  formFieldCss: PropTypes.shape({}),
  marketoFieldName: PropTypes.string,
  optional: PropTypes.bool,
  placeholder: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  textLabel: PropTypes.string.isRequired,
};

InputFormField.defaultProps = {
  formFieldCss: {},
  marketoFieldName: '',
  optional: false,
};

export default InputFormField;

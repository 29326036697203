import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';

import loadable from '@loadable/component';
import TableRow from '../../atoms/TableRow/TableRow';
import { unitConverter as uc } from '../../../styles/base';

const TableCell = loadable(() => import('../../atoms/TableCell/TableCell'));

const tableCss = css`
  width: 100%;
  margin-bottom: ${uc('16px')};
  border: 1px solid #dee2e6;
`;

const containerCss = css`
  overflow-x: auto;
`;

const Table = ({ headers, rows }) => {
  const dataRows = headers ? rows.slice(1) : rows;
  return (
    <div css={containerCss}>
      <table css={tableCss}>
        <thead>
          {headers && (
            <TableRow>
              {rows[0].cells.map(cell => (
                <TableCell header>{cell}</TableCell>
              ))}
            </TableRow>
          )}
        </thead>
        <tbody>
          {dataRows.map(row => (
            <TableRow key={row._key}>
              {row.cells.map(cell => (
                <TableCell>{cell}</TableCell>
              ))}
            </TableRow>
          ))}
        </tbody>
      </table>
    </div>
  );
};

Table.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      cells: PropTypes.arrayOf(PropTypes.string),
    })
  ).isRequired,
  headers: PropTypes.bool,
};

Table.defaultProps = {
  headers: true,
};

export default Table;

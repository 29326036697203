import PropTypes from 'prop-types';
/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import { unitConverter as uc } from '../../../styles/base';

const isTest = process.env.NODE_ENV === 'test';

const Image = ({
  alt,
  className,
  lazyLoad,
  src,
  title,
  translateX,
  translateY,
}) => {
  const imageTranslateX = translateX ? uc(`${translateX}px`) : '';
  const imageTranslateY = translateY ? uc(`${translateY}px`) : '';
  // setting these only if we have to as it affects the stacking order
  const translateXValueForCss =
    imageTranslateX !== '' ? `translateX(${imageTranslateX})` : '';
  const translateYValueForCss =
    imageTranslateY !== '' ? `translateY(${imageTranslateY})` : '';

  const imageCss =
    translateXValueForCss !== '' || translateYValueForCss !== ''
      ? css`
          transform: ${translateXValueForCss} ${translateYValueForCss};
        `
      : '';

  const imgProps = {
    src,
    title,
    css: imageCss,
    className,
  };
  return !isTest && lazyLoad ? (
    <LazyLoadImage
      {...imgProps}
      alt={alt}
      effect="opacity"
      wrapperClassName="img-wrapper"
      threshold={500}
    />
  ) : (
    <img {...imgProps} alt={alt} />
  );
};

Image.propTypes = {
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]).isRequired,
  alt: PropTypes.string,
  title: PropTypes.string,
  translateX: PropTypes.number,
  translateY: PropTypes.number,
  className: PropTypes.string,
  lazyLoad: PropTypes.bool,
};

Image.defaultProps = {
  title: '',
  alt: 'image',
  translateX: null,
  translateY: null,
  className: '',
  lazyLoad: true,
};

export default Image;

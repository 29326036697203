import { useMemo } from 'react';
import { createLocaleTextGetter } from '../utils/localeUtils';

export default function useLocalize(data, locale) {
  const localizedData = useMemo(() => {
    const localize = createLocaleTextGetter(locale);
    return localize(data);
  }, [data, locale]);
  return localizedData;
}

import React, { useState } from 'react';
import styled from '@emotion/styled';
import {
  colors,
  durations,
  pageDimensions,
  rgba,
  shadows,
  unitConverter as uc,
} from '../../../../styles/base';
import useScrollToTop from '../../../../hooks/useScrollToTop';

const BackToTopButton = () => {
  const [displayButton, setDisplayButton] = useState(true);

  const toggleBackToTop = entries => {
    setDisplayButton(entries[0].isIntersecting);
  };

  const { scrollBackToTop } = useScrollToTop(toggleBackToTop);

  const ButtonStyled = styled.button`
    position: fixed;
    right: calc(50vw - (${pageDimensions.desktopWidth} / 2) - ${uc('100px')});
    bottom: ${uc('100px')};
    z-index: 10000;
    width: ${uc('60px')};
    height: ${uc('60px')};
    background: rgba(${rgba(colors.white, 0.2)});
    border: none;
    border-radius: ${uc('6px')};
    outline: none;
    box-shadow: ${shadows.small};
    opacity: ${displayButton ? 1 : 0};
    transition: ${durations.fast};
    animation: 1s;
    pointer-events: ${displayButton ? 'auto' : 'none'};

    &::after,
    &::before {
      position: absolute;
      top: calc(50% + ${uc('3.2px')});
      left: 50%;
      width: ${uc('13.2px')};
      height: ${uc('13.2px')};
      color: ${colors.lightGray.three};
      border-top: 0.125rem solid currentColor;
      border-right: 0.125rem solid currentColor;
      transform: translate(-50%, -50%) rotate(-45deg);
      transition: ${durations.medium};
      content: '';
    }

    &:hover,
    &:active,
    &:focus {
      background: rgba(${rgba(colors.white, 0.8)});
      box-shadow: ${shadows.smallHovered};
      cursor: pointer;

      &::after {
        color: ${colors.lightGray.four};
        transform: translate(-50%, calc(-50% - ${uc('4px')})) rotate(-45deg);
      }

      &::before {
        color: ${colors.lightGray.four};
        transform: translate(-50%, calc(-50% + ${uc('4px')})) rotate(-45deg);
      }
    }
  `;

  return (
    <ButtonStyled
      type="button"
      onClick={scrollBackToTop}
      aria-label="Back To Top"
    />
  );
};

export default BackToTopButton;

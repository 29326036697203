import React from 'react';
import PropTypes from 'prop-types';

export const LocaleContext = React.createContext({
  locale: '',
});

export const LocaleContextProvider = ({ children, locale }) => {
  return (
    <LocaleContext.Provider value={{ locale }}>
      {children}
    </LocaleContext.Provider>
  );
};

LocaleContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node.isRequired,
    PropTypes.func.isRequired,
  ]).isRequired,
  locale: PropTypes.string.isRequired,
};

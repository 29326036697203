import PropTypes from 'prop-types';
import { Fragment } from 'react';
/** @jsx jsx needed to get Storybook to render */
import { css, jsx } from '@emotion/core';
import {
  breakpoints,
  buttonColors,
  durations,
  fontFamilies,
  fontWeights,
  unitConverter as uc,
} from '../../../../styles/base';
import Link from '../../Link/Link';

const buttonEasing = 'cubic-bezier(0.65, 0, 0.076, 1)';

const AnimatedButton = ({ _key, children, className, color, link }) => {
  /* CSS imported from https://codepen.io/kathykato/pen/rZRaNe */
  const buttonCss = css`
    position: relative;
    display: inline-block;
    padding: 0;
    text-decoration: none;
    vertical-align: middle;
    background: transparent;
    border: 0;
    outline: none;
    cursor: pointer;

    @media (${breakpoints.mobile}) {
      width: 100%;
    }

    .square {
      position: absolute;
      width: ${uc('46.4px')};
      height: ${uc('43.2px')};
      margin: 0;
      background: ${buttonColors[color].background};
      border-radius: ${uc('14px')};
      transition: all ${durations.slow} ${buttonEasing};

      .icon {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        background: ${buttonColors[color].background};
        transition: all ${durations.slow} ${buttonEasing};

        &.arrow {
          left: ${uc('10px')};
          width: ${uc('18px')};
          height: ${uc('2px')};
          background: none;
          transition: all ${durations.slow} ${buttonEasing};

          &::before {
            position: absolute;
            top: ${uc('-4px')};
            right: ${uc('1px')};
            width: ${uc('10px')};
            height: ${uc('10px')};
            border-top: ${uc('2px')} solid ${buttonColors[color].color};
            border-right: ${uc('2px')} solid ${buttonColors[color].color};
            transform: rotate(45deg);
            content: '';
          }
        }
      }

      @media (${breakpoints.mobile}) {
        width: 100%;
        transition: none;
      }
    }

    .button-text {
      position: relative;
      display: flex;
      align-items: center;
      height: ${uc('43.2px')};
      margin: ${uc('0 0 0 58.4px')};
      color: ${color === 'lightGray'
        ? buttonColors[color].color
        : buttonColors[color].background};
      font-weight: ${fontWeights.bold};
      font-family: ${fontFamilies.proximaSoft};
      text-align: center;
      text-transform: lowercase;
      transition: all ${durations.slow} ${buttonEasing};

      @media (${breakpoints.mobile}) {
        margin: ${uc('0 10px 0 40px')};
        color: ${buttonColors[color].color};
      }
    }

    @media (min-width: ${uc('481px')}) {
      &:hover {
        .square {
          width: calc(100% + ${uc('32px')});

          .icon {
            &.arrow {
              background: ${buttonColors[color].color};
              transform: translate(${uc('16px')}, 0);
            }
          }
        }

        .button-text {
          color: ${buttonColors[color].color};
        }
      }
    }
  `;

  const ButtonContents = (
    <Fragment>
      <div className="square">
        <span className="icon arrow" />
      </div>
      <p className="button-text">{children}</p>
    </Fragment>
  );

  if (link) {
    return (
      <Link to={link} css={buttonCss} className={className}>
        {ButtonContents}
      </Link>
    );
  }

  return (
    <button type="button" className={`button-id-${_key}`} css={buttonCss}>
      {ButtonContents}
    </button>
  );
};

AnimatedButton.propTypes = {
  _key: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string,
  link: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
};

AnimatedButton.defaultProps = {
  _key: '',
  children: '',
  className: '',
  color: 'gray',
  link: '',
};

export default AnimatedButton;

import PropTypes from 'prop-types';
/** @jsx jsx needed to get Storybook to render */
import { css, jsx } from '@emotion/core'; // eslint-disable-line no-unused-vars
import {
  breakpoints,
  colors,
  fontFamilies,
  fontSizes,
  lineHeights,
  unitConverter as uc,
} from '../../../styles/base';
import Link from '../../atoms/Link/Link';

const Copyright = ({ copyright }) => {
  if (copyright.length < 1) return null;

  const copyrightCss = css`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    width: ${uc('400px')};
    margin-top: ${uc('8px')};
    color: ${colors.lightBlue};
    font-size: ${fontSizes.fourteen};
    font-family: ${fontFamilies.roboto};

    a {
      color: ${colors.lightBlue};
      text-decoration: none;
    }

    @media (${breakpoints.largeTablet}) {
      justify-content: flex-end;
      width: 100%;
      margin-top: 0;

      > * {
        padding: ${uc('0 10px')};
        text-align: center;
      }
    }

    @media (${breakpoints.tablet}) {
      justify-content: center;
      line-height: ${lineHeights.copy};
    }
  `;

  const renderedCopyright = copyright.map(copyrightLink => (
    <Link key={`link-component-${copyrightLink._key}`} to={copyrightLink}>
      {copyrightLink.text}
    </Link>
  ));

  return (
    <div css={copyrightCss}>
      {renderedCopyright}
      <div>©Cornerstone {new Date().getFullYear()}</div>
    </div>
  );
};

Copyright.propTypes = {
  copyright: PropTypes.arrayOf(PropTypes.shape({})),
};

Copyright.defaultProps = {
  copyright: [{}],
};

export default Copyright;

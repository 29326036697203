import PropTypes from 'prop-types';
/** @jsx jsx this comment is required for Storybook to render */
import { jsx } from '@emotion/core';
import NewsBarLarge from '../../molecules/NewsBar/NewsBarLarge';
import NewsBarSmall from '../../molecules/NewsBar/NewsBarSmall';

const NewsBarSection = ({
  newsBarSection: {
    backgroundColor,
    buttons,
    description,
    heading,
    link,
    newsBarType,
    notificationBarImage,
  },
}) => {
  const renderNewsBar = () => {
    if (newsBarType === 'large') {
      return (
        <NewsBarLarge
          backgroundColor={backgroundColor}
          buttons={buttons}
          description={description}
          heading={heading}
          notificationBarImage={notificationBarImage}
        />
      );
    }
    if (newsBarType === 'small') {
      return (
        <NewsBarSmall
          backgroundColor={backgroundColor}
          heading={heading}
          link={link}
        />
      );
    }
    return null;
  };
  return renderNewsBar();
};

NewsBarSection.propTypes = {
  newsBarSection: PropTypes.instanceOf(Object),
};

NewsBarSection.defaultProps = {
  newsBarSection: null,
};

export default NewsBarSection;

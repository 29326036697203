import { useEffect } from 'react';

export default function useScrollToTop(callback) {
  useEffect(() => {
    if (window && window.IntersectionObserver) {
      const observer = new IntersectionObserver(callback, {
        threshold: 0.0,
      });
      observer.observe(document.querySelector('footer'));
    }
  }, []);

  const scrollBackToTop = event => {
    event.stopPropagation();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  return { scrollBackToTop };
}

import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import {
  breakpoints,
  colors,
  fontSizes,
  lineHeights,
  fontFamilies,
} from '../../../../styles/base';

const Heading3 = styled.h3`
  color: ${props => props.color};
  font-size: ${props => props.fontSize};
  font-family: ${fontFamilies.proximaSoft};
  line-height: ${lineHeights.copy};
  letter-spacing: 1px;

  @media (${breakpoints.tablet}) {
    font-size: ${fontSizes.twentyFive};
  }

  @media (${breakpoints.mobile}) {
    font-size: ${fontSizes.twentyTwo};
  }
`;

Heading3.propTypes = {
  color: PropTypes.string,
  fontSize: PropTypes.string,
};

Heading3.defaultProps = {
  color: colors.darkGray.three,
  fontSize: fontSizes.twentyFive,
};

export default Heading3;

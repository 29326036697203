import React from 'react';
import PropTypes from 'prop-types';
import useLink from '../../../hooks/useLink';

// Since DOM elements <a> cannot receive activeClassName,
// destructure the prop here and pass it only to GatsbyLink
const Link = ({
  activeClassName,
  children,
  className,
  isLocalized,
  target,
  to,
}) => {
  // Handle error when to value is null (logic needs refactor)
  if (!to || !to.link) {
    return null;
  }

  const href = useLink(to, isLocalized);

  const aProps = { href, className };
  if (activeClassName) aProps.activeclassname = activeClassName;
  if (target) aProps.target = target;

  return <a {...aProps}>{children}</a>;
};

Link.propTypes = {
  activeClassName: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
  isLocalized: PropTypes.bool,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  target: PropTypes.string,
};

Link.defaultProps = {
  activeClassName: '',
  children: '',
  className: null,
  isLocalized: true,
  to: null,
  target: null,
};

export default Link;

const pipe = require('lodash/fp/pipe');
const getOr = require('lodash/fp/getOr');
const cond = require('lodash/fp/cond');
const negate = require('lodash/fp/negate');
const split = require('lodash/fp/split');
const kebabCase = require('lodash/fp/kebabCase');

const matchLocale = x => {
  return /^\w{2}$/g.test(x);
};

const getLocaleFromPath = () => {
  if (typeof window !== 'undefined') {
    return pipe(
      getOr('', 'location.pathname'),
      split('/'),
      getOr('', '1'),
      cond([
        [matchLocale, x => x],
        [negate(matchLocale), () => 'us'],
      ])
    )(window);
  }
  return 'us';
};

const getLocalePrefix = locale =>
  locale !== 'us' ? `/${kebabCase(locale)}` : '';

function forceTrailingSlash(link) {
  if (typeof link === 'string' && link.length > 1) {
    if (link.includes('?')) {
      const linkParts = link.split('?');
      linkParts[0] = linkParts[0].replace(/\/$|$/, `/`);
      return linkParts.join('?');
    }

    return link === `/` ? link : link.replace(/\/$|$/, `/`);
  }

  return link;
}

function shouldAddTrailingSlash(link) {
  return (
    typeof link === 'string' &&
    link.length > 1 &&
    !link.startsWith('http') &&
    link !== '/'
  );
}

module.exports = {
  forceTrailingSlash,
  shouldAddTrailingSlash,
  getLocaleFromPath,
  getLocalePrefix,
};

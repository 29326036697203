import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import {
  breakpoints,
  colors,
  fontSizes,
  fontFamilies,
} from '../../../../styles/base';

const Heading2 = styled.h2`
  color: ${props => props.color};
  font-size: ${props => props.fontSize};
  font-family: ${fontFamilies.proximaSoft};
  letter-spacing: 1px;

  @media (${breakpoints.tablet}) {
    font-size: ${fontSizes.thirtyTwo};
  }

  @media (${breakpoints.mobile}) {
    font-size: ${fontSizes.twentyEight};
  }
`;

Heading2.propTypes = {
  color: PropTypes.string,
  fontSize: PropTypes.string,
};

Heading2.defaultProps = {
  color: colors.darkGray.three,
  fontSize: fontSizes.thirtyEight,
};

export default Heading2;

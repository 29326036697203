/**
 * Renders a Heading with a list of links
 */
import PropTypes from 'prop-types';
/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';
import {
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  headingColors,
  lineHeights,
  unitConverter as uc,
} from '../../../../styles/base';
import { Heading4 } from '../../../atoms/Headings/Headings';
import Link from '../../../atoms/Link/Link';

const FooterMenuColumn = ({ headingText, links }) => {
  const linkCss = css`
    display: block;
    margin-bottom: ${uc('10px')};
    color: ${colors.mediumGray};
    font-weight: ${fontWeights.medium};
    font-size: ${fontSizes.fourteen};
    font-family: ${fontFamilies.roboto};
    line-height: ${lineHeights.copy};

    &:active,
    &:focus,
    &:hover {
      color: ${colors.accent};
    }
  `;

  const heading4Css = css`
    margin-bottom: ${uc('18px')};
    color: ${headingColors.black};
    font-size: ${fontSizes.fourteen};
    letter-spacing: 0;
  `;

  const renderedLinks = links.map(singleLink => (
    <Link
      css={linkCss}
      key={`menu-link-component-${singleLink._key}`}
      to={singleLink}
    >
      {singleLink.text}
    </Link>
  ));

  return (
    <div>
      <Heading4 css={heading4Css}>{headingText}</Heading4>
      {renderedLinks}
    </div>
  );
};

/**
 * Prop types
 */
FooterMenuColumn.propTypes = {
  headingText: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.shape({})),
};

/**
 * Default props
 */
FooterMenuColumn.defaultProps = {
  headingText: '',
  links: [],
};

export default FooterMenuColumn;

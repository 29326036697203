import { Fragment, useRef } from 'react';
import PropTypes from 'prop-types';
/** @jsx jsx needed to get Storybook to render */
import { css, jsx } from '@emotion/core';
import SVG from 'react-inlinesvg';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import Button from '../Button/Button';
import videoButtonArrowPng from './assets/video-button-arrow.png';
import closeBtn from '../../../../../static/svg/close-button.svg';
import {
  colors,
  durations,
  rgba,
  unitConverter as uc,
} from '../../../../styles/base';

const VideoButton = ({
  _key,
  buttonOutline,
  children,
  className,
  color,
  video,
}) => {
  if (children === '') {
    return null;
  }

  const buttonContentsCss = css`
    display: flex;
    align-items: center;
  `;

  const arrowCss = css`
    height: ${uc('18px')};
    margin-right: ${uc('10px')};
    vertical-align: middle;
  `;

  const overlayCss = css`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    display: block;
    background-color: rgba(${rgba(colors.black, 0.8)});
    visibility: hidden;
    opacity: 0;
    transition: all ${durations.medium} ease-in-out;

    &.open {
      visibility: visible;
      opacity: 100;
    }
  `;

  const videoWrapperCss = css`
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 101;
    display: block;
    width: 75%;
    background-color: ${colors.black};
    transform: translateY(-50%) translateX(-50%);
    visibility: hidden;
    opacity: 0;
    transition: all ${durations.medium} ease-in-out;

    &.open {
      visibility: visible;
      opacity: 100;
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 101;
      width: 100%;
      height: 100%;
      pointer-events: auto;
    }
  `;

  const videoCss = css`
    position: relative;
    height: 0;
    padding-top: ${uc('25px')};
    padding-bottom: 56.25%; /* 16:9 */
  `;

  const closeBtnCss = css`
    position: absolute;
    top: ${uc('20px')};
    right: ${uc('20px')};
    z-index: 101;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    opacity: 0.6;
    transition: all ${durations.medium} ease-in-out;

    svg {
      width: ${uc('30px')};
      height: ${uc('30px')};

      .st0 {
        fill: ${colors.white};
        stroke: ${colors.white};
      }
    }

    &:active,
    &:focus,
    &:hover {
      opacity: 0.8;
    }
  `;

  const videoButtonArrowImg = (
    <img css={arrowCss} src={videoButtonArrowPng} alt="video button arrow" />
  );

  const overlayRef = useRef(null);
  const videoRef = useRef(null);

  /**
   * Clone the modal divs and prepend them to body so that the z-index values
   * aren't affected by their parents.
   */
  const cloneModal = () => {
    const newVideoModal = videoRef.current.cloneNode(true);
    const newOverlayModal = overlayRef.current.cloneNode(true);
    const body = document.querySelector('body');

    // only autoplay on click
    newVideoModal.innerHTML = newVideoModal.innerHTML.replace(
      'autoplay=0',
      'autoplay=1'
    );

    body.insertBefore(newVideoModal, body.firstChild);
    body.insertBefore(newOverlayModal, body.firstChild);

    newOverlayModal.classList.add('open');
    newVideoModal.classList.add('open');

    newOverlayModal.addEventListener('click', () => {
      body.removeChild(newOverlayModal);
      body.removeChild(newVideoModal);
    });
  };

  /**
   * Open the modal window.
   */
  const openModal = () => {
    cloneModal();
  };

  let buttonID = _key;
  if (!_key) {
    buttonID = 'video-button';
  }

  return (
    <Fragment>
      <Button
        onClick={openModal}
        onKeyUp={openModal}
        outline={buttonOutline}
        className={className}
        color={color}
        _key={buttonID}
      >
        <div css={buttonContentsCss} data-cy="video-button">
          {!buttonOutline && videoButtonArrowImg}
          {children}
        </div>
      </Button>
      <div ref={overlayRef} css={overlayCss} role="dialog">
        <button
          className="close-button"
          data-cy="close-button"
          type="button"
          css={closeBtnCss}
        >
          <SVG src={closeBtn} />
        </button>
      </div>
      <LazyLoadComponent threshold={500}>
        <div ref={videoRef} css={videoWrapperCss}>
          <div
            css={videoCss}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: video.embedCode }}
          />
        </div>
      </LazyLoadComponent>
    </Fragment>
  );
};

VideoButton.propTypes = {
  _key: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string,
  buttonOutline: PropTypes.bool,
  video: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
};

VideoButton.defaultProps = {
  _key: '',
  children: '',
  className: '',
  color: 'gray',
  buttonOutline: false,
  video: false,
};

export default VideoButton;

// country lists for each website
export const gdprCountries = [
  'Austria',
  'Belgium',
  'Bulgaria',
  'Croatia',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Estonia',
  'Finland',
  'France',
  'Germany',
  'Greece',
  'Hungary',
  'Ireland',
  'Italy',
  'Latvia',
  'Lithuania',
  'Luxembourg',
  'Malta',
  'Netherlands',
  'New Zealand',
  'Poland',
  'Portugal',
  'Romania',
  'Slovakia',
  'Slovenia',
  'Spain',
  'Sweden',
  'United Kingdom',
];

export const emailOptInCountries = [...gdprCountries, 'Australia', 'Canada'];

export const provinces = [
  { abbreviation: 'AB', name: 'Alberta' },
  { abbreviation: 'BC', name: 'British Columbia' },
  { abbreviation: 'MB', name: 'Manitoba' },
  { abbreviation: 'NB', name: 'New Brunswick' },
  { abbreviation: 'NL', name: 'Newfoundland/Labrador' },
  { abbreviation: 'NT', name: 'Northwest Territories' },
  { abbreviation: 'NS', name: 'Nova Scotia' },
  { abbreviation: 'NU', name: 'Nunavut' },
  { abbreviation: 'ON', name: 'Ontario' },
  { abbreviation: 'PE', name: 'Prince Edward Island' },
  { abbreviation: 'QC', name: 'Quebec' },
  { abbreviation: 'SK', name: 'Saskatchewan' },
  { abbreviation: 'YT', name: 'Yukon' },
];

export const provincesLabel = {
  au: 'Province',
  br: 'Província',
  de: 'Provinz',
  es: 'Provincia',
  fr: 'Province',
  in: 'Province',
  it: 'Provincia',
  jp: '州',
  kr: 'Province',
  la: 'Provincia',
  nl: 'Province',
  us: 'Province',
  uk: 'Province',
};

export const states = [
  { abbreviation: 'AL', name: 'Alabama' },
  { abbreviation: 'AK', name: 'Alaska' },
  { abbreviation: 'AZ', name: 'Arizona' },
  { abbreviation: 'AR', name: 'Arkansas' },
  { abbreviation: 'CA', name: 'California' },
  { abbreviation: 'CO', name: 'Colorado' },
  { abbreviation: 'CT', name: 'Connecticut' },
  { abbreviation: 'DE', name: 'Delaware' },
  { abbreviation: 'DC', name: 'District of Columbia' },
  { abbreviation: 'FL', name: 'Florida' },
  { abbreviation: 'GA', name: 'Georgia' },
  { abbreviation: 'HI', name: 'Hawaii' },
  { abbreviation: 'ID', name: 'Idaho' },
  { abbreviation: 'IL', name: 'Illinois' },
  { abbreviation: 'IN', name: 'Indiana' },
  { abbreviation: 'IA', name: 'Iowa' },
  { abbreviation: 'KS', name: 'Kansas' },
  { abbreviation: 'KY', name: 'Kentucky' },
  { abbreviation: 'LA', name: 'Louisiana' },
  { abbreviation: 'ME', name: 'Maine' },
  { abbreviation: 'MD', name: 'Maryland' },
  { abbreviation: 'MA', name: 'Massachusetts' },
  { abbreviation: 'MI', name: 'Michigan' },
  { abbreviation: 'MN', name: 'Minnesota' },
  { abbreviation: 'MS', name: 'Mississippi' },
  { abbreviation: 'MO', name: 'Missouri' },
  { abbreviation: 'MT', name: 'Montana' },
  { abbreviation: 'NE', name: 'Nebraska' },
  { abbreviation: 'NV', name: 'Nevada' },
  { abbreviation: 'NH', name: 'New Hampshire' },
  { abbreviation: 'NJ', name: 'New Jersey' },
  { abbreviation: 'NM', name: 'New Mexico' },
  { abbreviation: 'NY', name: 'New York' },
  { abbreviation: 'NC', name: 'North Carolina' },
  { abbreviation: 'ND', name: 'North Dakota' },
  { abbreviation: 'OH', name: 'Ohio' },
  { abbreviation: 'OK', name: 'Oklahoma' },
  { abbreviation: 'OR', name: 'Oregon' },
  { abbreviation: 'PA', name: 'Pennsylvania' },
  { abbreviation: 'RI', name: 'Rhode Island' },
  { abbreviation: 'SC', name: 'South Carolina' },
  { abbreviation: 'SD', name: 'South Dakota' },
  { abbreviation: 'TN', name: 'Tennessee' },
  { abbreviation: 'TX', name: 'Texas' },
  { abbreviation: 'UT', name: 'Utah' },
  { abbreviation: 'VT', name: 'Vermont' },
  { abbreviation: 'VA', name: 'Virginia' },
  { abbreviation: 'WA', name: 'Washington' },
  { abbreviation: 'WV', name: 'West Virginia' },
  { abbreviation: 'WI', name: 'Wisconsin' },
  { abbreviation: 'WY', name: 'Wyoming' },
];

export const statesLabel = {
  au: 'State',
  br: 'Estado',
  de: 'Bundesland',
  es: 'Estado',
  fr: 'Pays',
  in: 'State',
  it: 'Stato',
  jp: '州',
  kr: 'State',
  la: 'Estado',
  nl: 'State',
  us: 'State',
  uk: 'State',
};

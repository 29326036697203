import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { Global, css } from '@emotion/core';
import { Helmet } from 'react-helmet';
import loadable from '@loadable/component';
import {
  colors,
  fontFamilies,
  fontSizes,
  pageDimensions,
  unitConverter as uc,
} from '../../../styles/base';
import GatsbyFooter from '../Footer/GatsbyFooter';
import BackToTopButton from '../../atoms/Buttons/BackToTopButton/BackToTopButton';
import './Layout.css';
import NewsBarSection from '../NewsBarSection/NewsBarSection';
import sanityImage from '../../../utils/sanityImage';
import { configForDataset } from '../../../../../backend/config/intlConfig';
import sanityJson from '../../../../../backend/sanity.json';
import defaultOGImage from './assets/csod-og-image-default.png';
import EditPage from './EditPage';
import { LocaleContextProvider } from '../../../context/LocaleContext';
import languageFilterConfig from '../../../../../backend/config/languageFilterConfig';
import { getLocalePrefix } from '../../../utils/urlUtils';

const Header = loadable(() => import('../Header/Header'));
const GDPR = loadable(() => import('../../molecules/GDPR/GDPR'));

// use this menu if jest testing
const sanityFooterMenu = {
  _rawIcon: {},
  headingText: null,
  _rawButton: {},
  description: null,
  phoneNumber: null,
  _rawFooterMenuColumns: [],
  _rawSubscribeForm: {
    _type: 'form',
    action: {
      _id: 'f3faf0cb-7d85-48b2-8a68-db3f7f6776b4',
      _type: 'route',
      _createdAt: '2019-12-23T20:01:27Z',
      _rev: 'b1T4WxDkdcyEC5mekLmFFn',
      _updatedAt: '2019-12-23T20:01:27Z',
      metaDescription: 'Thank you for subscribing to the newsletter',
      routeName: 'Newsletter Subscribe Thank You ',
      slug: {
        _type: 'slug',
        current: '/thank-you/newsletter-subscribe',
      },
      id: '7eac77e6-8c45-5e26-826b-d3f01a782a69',
      parent: null,
      children: [],
      internal: {
        type: 'SanityRoute',
        contentDigest: '5c95f52ca6a7f0385fc9cf535c95b243',
        owner: 'gatsby-source-sanity',
      },
    },
    formFields: [
      {
        _key: 'c6af6a98821e',
        _type: 'reusableFormField',
        formFieldReference: {
          _id: 'fe9d427a-520f-4dd9-b88a-10ec1d8213ab',
          _type: 'emailInputFormFieldReference',
          _createdAt: '2019-12-23T20:04:04Z',
          _rev: 'tuvYsozglTCpqg5cva4JeF',
          _updatedAt: '2019-12-23T20:04:16Z',
          marketoFieldName: 'email',
          placeholder: "What's your email?",
          textLabel: 'Footer Email',
          id: 'b425c502-eebe-5c4e-b37d-a6f17025ff38',
          parent: null,
          children: [],
          internal: {
            type: 'SanityEmailInputFormFieldReference',
            contentDigest: 'e1e6bb8da8db1f5b78166633d1d3f7e5',
            owner: 'gatsby-source-sanity',
          },
        },
      },
    ],
    formType: 'footer',
    name: 'footer',
    submitButton: {
      _type: 'button',
      buttonOutline: true,
      color: 'accent',
      text: 'subscribe',
    },
    title: 'Get awesome HR content in your inbox weekly',
  },
  _rawSocialIcons: [],
  _rawCopyright: [],
};

const layoutCss = css`
  max-width: ${pageDimensions.largeDesktopWidth};
  margin: 0 auto;
  margin-bottom: ${uc('-20px')}; /* get rid of box shadow on bottom */
  color: ${colors.mediumGray};
  font-size: ${fontSizes.sixteen};
  font-family: ${fontFamilies.roboto};
  background-color: ${colors.white};
  box-shadow: ${uc('0 0 71px 10px')} rgba(45, 69, 92, 0.25098039215686274),
    ${uc('0 0 100px 50px')} rgba(45, 69, 92, 0.3411764705882353);

  main {
    overflow: hidden;
  }
`;

const Layout = ({
  children,
  href,
  locale,
  menuColor,
  metaDescription,
  metaKeywords,
  metaTitle,
  newsBarSection,
  ogDescription,
  ogImage,
  ogLocale,
  ogTitle,
  ogType,
  pageCss,
  routeName,
  sanityId,
  test,
  twitterCard,
  twitterDescription,
  twitterImage,
  twitterSite,
  twitterTitle,
}) => {
  const gatsbyData = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            env
          }
        }
      }
    `
  );

  const metaTitleText = !metaTitle ? `${routeName} | Cornerstone` : metaTitle;
  const ogTitleText = ogTitle || metaTitleText;
  const ogDescriptionText = ogDescription || metaDescription;
  const ogImageTags =
    ogImage && ogImage.asset
      ? [
          <meta
            key="og:image"
            property="og:image"
            content={sanityImage(ogImage.asset)
              .auto('format')
              .url()}
          />,
          <meta
            key="og:image:width"
            property="og:image:width"
            content={ogImage.asset.metadata.dimensions.width}
          />,
          <meta
            key="og:image:height"
            property="og:image:height"
            content={ogImage.asset.metadata.dimensions.height}
          />,
        ]
      : [
          <meta
            key="og:image"
            property="og:image"
            content={`https://www.cornerstoneondemand.com${defaultOGImage}`}
          />,
          <meta
            key="og:image:width"
            property="og:image:width"
            content="1200"
          />,
          <meta
            key="og:image:height"
            property="og:image:height"
            content="1200"
          />,
        ];
  const defaultTwitterAccount =
    configForDataset(sanityJson.api.dataset).social &&
    configForDataset(sanityJson.api.dataset).social.twitter
      ? configForDataset(sanityJson.api.dataset).social.twitter
      : 'CornerstoneInc';
  const twitterSiteText = twitterSite || defaultTwitterAccount;
  const twitterImageTags =
    twitterImage && twitterImage.asset
      ? [
          <meta
            key="twitter:image"
            property="twitter:image"
            content={sanityImage(twitterImage.asset)
              .auto('format')
              .url()}
          />,
          <meta
            key="twitter:image:alt"
            property="twitter:image:alt"
            content={twitterImage.alt}
          />,
        ]
      : [];
  return (
    <LocaleContextProvider locale={locale}>
      <Fragment>
        <Global
          styles={css`
            @import url('https://fonts.googleapis.com/css?family=proxima-soft:400,600,700|Roboto:300,400,500,600,700&display=swap');
            @import url('https://use.typekit.net/ljn4lxs.css');
          `}
        />
        <Helmet title={metaTitleText} defer={false}>
          <meta name="description" content={metaDescription} />
          <meta name="keywords" content={metaKeywords} />
          <meta property="og:url" content={href} />
          <meta property="og:type" content={ogType} />
          <meta property="og:title" content={ogTitleText} />
          <meta property="og:description" content={ogDescriptionText} />
          {ogImageTags}
          <meta property="og:locale" content={ogLocale} />
          <meta property="twitter:card" content={twitterCard} />
          <meta property="twitter:site" content={twitterSiteText} />
          {twitterTitle && (
            <meta property="twitter:title" content={twitterTitle} />
          )}
          {twitterDescription && (
            <meta property="twitter:description" content={twitterDescription} />
          )}
          {languageFilterConfig.supportedLanguages.map(supportedLanguage => {
            return supportedLanguage.countries.map(country => {
              if (typeof window !== 'undefined') {
                const isUs = country === 'US';
                const { origin, pathname } = window.location;
                /**
                 * pathname for locales come in as ex: `as/company/founders`,
                 * `de/company/founders` etc we need to remove the locale so
                 * we can create the correct href per each supportedLocale
                 */
                const basePath = pathname.replace(/\/\w{2}(-\w{2})?\//, '/');
                const localeHref = isUs
                  ? `${origin}${basePath}`
                  : `${origin}${getLocalePrefix(
                      supportedLanguage.id
                    )}${basePath}`;
                const hrefLang = isUs
                  ? 'x-default'
                  : `${supportedLanguage.language}-${country}`;
                return (
                  <link rel="alternate" href={localeHref} hrefLang={hrefLang} />
                );
              }
              return null;
            });
          })}
          {twitterImageTags}
          {gatsbyData && gatsbyData.site.siteMetadata.env !== 'production' && (
            <meta name="robots" content="noindex" />
          )}
        </Helmet>

        <GDPR />
        {routeName === 'Homepage' && newsBarSection && (
          <NewsBarSection newsBarSection={newsBarSection} />
        )}

        <Header menuColor={menuColor} routeName={routeName} />
        <div css={layoutCss}>
          <EditPage sanityId={sanityId} />
          <main css={pageCss}>{children}</main>
          {test ? <GatsbyFooter footer={sanityFooterMenu} /> : <GatsbyFooter />}
          <BackToTopButton />
        </div>
      </Fragment>
    </LocaleContextProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  menuColor: PropTypes.string,
  metaDescription: PropTypes.string,
  metaKeywords: PropTypes.string,
  metaTitle: PropTypes.string,
  newsBarSection: PropTypes.instanceOf(Object),
  ogDescription: PropTypes.string,
  ogImage: PropTypes.instanceOf(Object),
  ogLocale: PropTypes.string,
  ogTitle: PropTypes.string,
  ogType: PropTypes.string,
  pageCss: PropTypes.instanceOf(Object),
  routeName: PropTypes.string,
  sanityId: PropTypes.string.isRequired,
  test: PropTypes.bool,
  twitterCard: PropTypes.string,
  twitterDescription: PropTypes.string,
  twitterImage: PropTypes.instanceOf(Object),
  twitterSite: PropTypes.string,
  twitterTitle: PropTypes.string,
};

Layout.defaultProps = {
  menuColor: 'primary',
  metaDescription: '',
  metaKeywords: '',
  metaTitle: null,
  newsBarSection: null,
  ogDescription: null,
  ogImage: null,
  ogLocale: 'en_US',
  ogTitle: null,
  ogType: 'website',
  pageCss: null,
  routeName: '',
  test: false,
  twitterCard: 'summary',
  twitterDescription: null,
  twitterImage: null,
  twitterSite: null,
  twitterTitle: null,
};

export default Layout;

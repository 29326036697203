import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { fontSizes, colors, fontFamilies } from '../../../../styles/base';

const Heading6 = styled.h6`
  color: ${props => props.color};
  font-size: ${props => props.fontSize};
  font-family: ${fontFamilies.proximaSoft};
`;

Heading6.propTypes = {
  color: PropTypes.string,
  fontSize: PropTypes.string,
};

Heading6.defaultProps = {
  color: colors.darkGray.three,
  fontSize: fontSizes.fourteen,
};

export default Heading6;

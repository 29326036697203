import PropTypes from 'prop-types';
/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';

import {
  backgroundColors,
  breakpoints,
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  letterSpacings,
  lineHeights,
  unitConverter as uc,
} from '../../../styles/base';

import Link from '../../atoms/Link/Link';
import PageWrapper from '../../organisms/PageWrapper/PageWrapper';
import Section from '../Section/Section';
import Text from '../../atoms/Text/Text';

const NewsBarSmall = ({ backgroundColor, heading, link }) => {
  const textColor =
    backgroundColor === 'white' ? colors.darkGray : colors.white;
  const sectionCss = css`
    background: ${backgroundColors[backgroundColor]};

    @media (${breakpoints.tablet}) {
      padding: 0;
    }
  `;
  const newsBarSectionCss = css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    margin: ${uc('0 50px')};
    padding: ${uc('3px')};
    line-height: ${lineHeights.solid};

    ${Text} {
      align-items: center;
      margin: 0;
      padding: ${uc('5px 0')};
      color: ${textColor};
      font-weight: ${fontWeights.normal};
      font-size: ${fontSizes.fourteen};
      letter-spacing: ${letterSpacings.normal};
    }

    a {
      margin: ${uc('auto 5px')};
      color: ${textColor};
      font-weight: ${fontWeights.bold};
      font-size: ${fontSizes.fourteen};
      font-family: ${fontFamilies.roboto};
    }

    @media (${breakpoints.tablet}) {
      ${Text} {
        width: 100%;
        margin: auto;
        padding: ${uc('5px 0')};
        text-align: center;
      }
    }

    @media (${breakpoints.mobile}) {
      margin: ${uc('0 20px')};

      ${Text},
      a {
        font-size: ${fontSizes.twelve};
      }
    }
  `;

  return (
    <div>
      <Section css={sectionCss}>
        <PageWrapper>
          <div css={newsBarSectionCss}>
            <Text>
              {heading}
              <Link to={link}>{link.text}</Link>
            </Text>
          </div>
        </PageWrapper>
      </Section>
    </div>
  );
};

NewsBarSmall.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  link: PropTypes.instanceOf(Object).isRequired,
};

export default NewsBarSmall;

import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import {
  breakpoints,
  colors,
  fontSizes,
  fontFamilies,
} from '../../../../styles/base';

const Heading1 = styled.h1`
  color: ${props => props.color};
  font-size: ${props => props.fontSize};
  font-family: ${fontFamilies.proximaSoft};
  letter-spacing: 1px;

  @media (${breakpoints.tablet}) {
    font-size: ${fontSizes.thirtyTwo};
    white-space: normal;
  }

  @media (${breakpoints.mobile}) {
    font-size: ${fontSizes.twentyEight};
  }
`;

Heading1.propTypes = {
  color: PropTypes.string,
  fontSize: PropTypes.string,
};

Heading1.defaultProps = {
  color: colors.white,
  fontSize: fontSizes.fourtySix,
};

export default Heading1;

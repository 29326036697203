import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import {
  colors,
  fontSizes,
  fontFamilies,
  lineHeights,
} from '../../../../styles/base';

const Heading4 = styled.h4`
  color: ${props => props.color};
  font-size: ${props => props.fontSize};
  font-family: ${fontFamilies.proximaSoft};
  line-height: ${lineHeights.copy};
`;

Heading4.propTypes = {
  color: PropTypes.string,
  fontSize: PropTypes.string,
};

Heading4.defaultProps = {
  color: colors.darkGray.three,
  fontSize: fontSizes.twenty,
};

export default Heading4;

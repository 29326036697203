/*
  DEPRECATED! Use <Grid> instead
*/

/** @jsx jsx this comment is required for Storybook to render */
// import dependencies
import styled from '@emotion/styled';
import {
  display,
  maxWidth,
  flex,
  flexWrap,
  flexDirection,
  alignItems,
  justifyContent,
} from 'styled-system';

// constructor
const DeprecatedGrid = styled.div`
  ${display}
  ${maxWidth}
  ${flex}
  ${flexWrap}
  ${flexDirection}
  ${alignItems}
  ${justifyContent}
`;

// props
DeprecatedGrid.propTypes = {
  ...display.PropTypes,
  ...maxWidth.propTypes,
  ...flex.propTypes,
  ...flexWrap.propTypes,
  ...flexDirection.propTypes,
  ...alignItems.propTypes,
  ...justifyContent.propTypes,
};

// default props
DeprecatedGrid.defaultProps = {
  display: 'flex',
  maxWidth: '100%',
  flex: 1,
  flexWrap: 'wrap',
  flexDirection: 'row',
  alignItems: 'stretch',
  justifyContent: 'normal',
};

export default DeprecatedGrid;

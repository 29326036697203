import React from 'react';
import AnimatedButton from '../components/atoms/Buttons/AnimatedButton/AnimatedButton';
import Button from '../components/atoms/Buttons/Button/Button';
import VideoButton from '../components/atoms/Buttons/VideoButton/VideoButton';

export const renderButtons = (buttons, buttonCss) => {
  return (
    <div css={buttonCss}>
      {buttons.map(button => {
        switch (button._type) {
          case 'videoButton':
            return (
              <VideoButton key={button._key} {...button}>
                {button.buttonText}
              </VideoButton>
            );
          case 'animatedButton':
            return (
              <AnimatedButton key={button._key} {...button}>
                {button.link.text}
              </AnimatedButton>
            );
          case 'button':
          default:
            return (
              <Button key={button._key} {...button}>
                {button.text}
              </Button>
            );
        }
      })}
    </div>
  );
};

export default {
  renderButtons,
};

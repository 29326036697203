import PropTypes from 'prop-types';
/** @jsx jsx needed to get Storybook to render */
import { css, jsx } from '@emotion/core'; // eslint-disable-line no-unused-vars
import SVG from 'react-inlinesvg';
import Link from '../../atoms/Link/Link';
import { breakpoints, colors, unitConverter as uc } from '../../../styles/base';
import PageWrapper from '../PageWrapper/PageWrapper';
import SocialIcons from './SocialIcons';
import FooterMenu from './FooterMenu/FooterMenu';
import Form from '../../molecules/Form/Form';
import Copyright from './Copyright';
import sanityJson from '../../../../../backend/sanity.json';
import { getLocale } from '../../../../../backend/config/intlConfig';

const locale = getLocale(sanityJson.api.dataset);

const csodLogo =
  locale === 'us'
    ? '/logo/csod-logo-realize-potential.svg'
    : '/logo/csod-logo-svg.svg';

const Footer = ({ sanityFooterMenu }) => {
  const footerProps = {
    icon: sanityFooterMenu._rawIcon,
    headingText: sanityFooterMenu.headingText,
    button: sanityFooterMenu._rawButton,
    description: sanityFooterMenu.description,
    phoneNumber: sanityFooterMenu.phoneNumber,
    footerMenuColumns: sanityFooterMenu._rawFooterMenuColumns,
    socialIcons: sanityFooterMenu._rawSocialIcons,
    copyright: sanityFooterMenu._rawCopyright,
    subscribeForm: sanityFooterMenu._rawSubscribeForm,
  };

  // to avoid being blocked by the bg of the section above
  const footerCss = css`
    position: relative;
    z-index: 3;
    background: ${colors.lightGray.six};
  `;

  const sectionCss = css`
    background: ${colors.primary};
  `;

  const pageWrapperCss = css`
    display: flex;
    min-height: ${uc('200px')};
    padding: ${uc('55px 55px 20px')};

    @media (${breakpoints.tablet}) {
      display: block;
      padding-top: ${uc('30px')};
    }
  `;

  const logoCss = css`
    fill: ${colors.white};

    svg {
      width: ${uc('228px')};
      height: ${uc('57.8px')};
    }

    .st0 {
      fill: ${colors.white};
    }
  `;

  const logoLinkCss = css`
    width: ${uc('228px')};
    height: ${uc('57.8px')};
  `;

  const logoContainerCss = css`
    display: flex;

    @media (${breakpoints.tablet}) {
      justify-content: center;
      align-items: center;
      padding-bottom: ${uc('20px')};
    }
  `;

  const bottomFooterCss = css`
    display: flex;
    flex: 1 0 50%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;

    > * {
      display: flex;
      flex: 1;
      min-width: ${uc('400px')};
      text-align: right;
    }

    @media (${breakpoints.largeTablet}) {
      > * {
        min-width: auto;
      }
    }

    @media (${breakpoints.tablet}) {
      display: block;
      flex: 1;
      align-items: center;
      clear: both;

      > * {
        justify-content: center;
        margin: ${uc('20px')} auto;
        text-align: center;
      }
    }
  `;

  return (
    <footer css={footerCss}>
      <PageWrapper css={pageWrapperCss}>
        <FooterMenu {...footerProps} />
      </PageWrapper>
      <section css={sectionCss}>
        <PageWrapper css={pageWrapperCss}>
          {footerProps.subscribeForm.enabled && (
            <Form formType="footer" {...footerProps.subscribeForm} />
          )}
          {!footerProps.subscribeForm.enabled && (
            <div css={logoContainerCss}>
              <Link to="/" css={logoLinkCss}>
                <SVG css={logoCss} src={csodLogo} alt="logo" />
              </Link>
            </div>
          )}
          <div css={bottomFooterCss}>
            <SocialIcons socialIcons={footerProps.socialIcons} />
            <Copyright copyright={footerProps.copyright} />
          </div>
        </PageWrapper>
      </section>
    </footer>
  );
};

Footer.propTypes = {
  sanityFooterMenu: PropTypes.shape({
    _rawIcon: PropTypes.shape({}),
    headingText: PropTypes.string,
    _rawButton: PropTypes.shape({}),
    description: PropTypes.string,
    phoneNumber: PropTypes.string,
    _rawFooterMenuColumns: PropTypes.arrayOf(PropTypes.shape({})),
    _rawSocialIcons: PropTypes.arrayOf(PropTypes.shape({})),
    _rawCopyright: PropTypes.arrayOf(PropTypes.shape({})),
    _rawSubscribeForm: PropTypes.shape({}),
  }),
};

Footer.defaultProps = {
  sanityFooterMenu: {},
};

export default Footer;

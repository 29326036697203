/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */

import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';

import { graphql, useStaticQuery } from 'gatsby';
import CsodBlockContent from '../../../molecules/BlockContent/BlockContent';

import {
  emailOptInCountries,
  gdprCountries,
  provinces,
  provincesLabel,
  states,
  statesLabel,
} from './CountriesStatesProvincesCheckboxes';
import { breakpoints, unitConverter as uc } from '../../../../styles/base';
import { LocaleContext } from '../../../../context/LocaleContext';

const visibleCss = css`
  [data-visibility='visible'] {
    display: flex;
  }

  [data-visibility='none'] {
    display: none;
  }
`;

const CountryFormField = ({
  emailOptInText,
  errors,
  formFieldCss,
  gdprText,
  marketoFieldName,
  register,
  textLabel,
}) => {
  const countriesData = useStaticQuery(graphql`
    {
      us: allUsJson {
        nodes {
          name
          displayName
          disabled
        }
      }
      apEn: allApEnJson {
        nodes {
          name
          displayName
          disabled
        }
      }
    }
  `);
  const { locale } = useContext(LocaleContext);
  const countries = countriesData[locale].nodes;
  const countryInputName = marketoFieldName || textLabel;
  const [selectedCountry, setSelectedCountry] = useState(countries[0].name);
  const isUSASelected = selectedCountry === 'United States';
  const isCanadaSelected = selectedCountry === 'Canada';
  const isGDPRCountry = gdprCountries.indexOf(selectedCountry) !== -1;
  const isEmailOptIn = emailOptInCountries.indexOf(selectedCountry) !== -1;

  const changeHandler = e => {
    setSelectedCountry(e.target.value);
  };

  const checkboxCss = css`
    ${formFieldCss}
    @media (${breakpoints.tablet}) {
      flex-flow: row;
      max-width: ${uc('400px')};
    }
  `;

  const privacyPolicyCss = css`
    ${checkboxCss} a {
      text-decoration: underline;
    }
  `;

  return (
    <div css={visibleCss}>
      <label css={formFieldCss} data-visibility="visible" required>
        <span>{textLabel}</span>
        <select
          name={countryInputName}
          className={
            Object.keys(errors).includes(countryInputName)
              ? 'input-error'
              : null
          }
          onChange={changeHandler}
          ref={register({ required: true })}
        >
          {countries.map(country => (
            <option
              key={`country-${country.name}`}
              value={country.name}
              disabled={country.disabled ? true : null}
            >
              {country.displayName}
            </option>
          ))}
        </select>
      </label>
      <label
        css={formFieldCss}
        data-visibility={isUSASelected ? 'visible' : 'none'}
        required={isUSASelected}
      >
        <span>{statesLabel[locale]}</span>
        <select
          name="State"
          data-visibility={isUSASelected ? 'visible' : 'none'}
          className={
            Object.keys(errors).includes('State') ? 'input-error' : null
          }
          defaultValue=""
          ref={register({ required: isUSASelected })}
        >
          <option key="state-none-selected" value="" disabled hidden>
            {statesLabel[locale]}
          </option>
          {states.map(state => (
            <option
              key={`state-${state.abbreviation}`}
              value={state.abbreviation}
            >
              {state.name}
            </option>
          ))}
        </select>
      </label>
      <label
        css={formFieldCss}
        data-visibility={isCanadaSelected ? 'visible' : 'none'}
        required={isCanadaSelected}
      >
        {provincesLabel[locale]}
        <select
          name="Province"
          data-visibility={isCanadaSelected ? 'visible' : 'none'}
          className={
            Object.keys(errors).includes('Province') ? 'input-error' : null
          }
          defaultValue=""
          ref={register({ required: isCanadaSelected })}
        >
          <option key="province-none-selected" value="" disabled hidden>
            {provincesLabel[locale]}
          </option>
          {provinces.map(province => (
            <option
              key={`province-${province.abbreviation}`}
              value={province.abbreviation}
            >
              {province.name}
            </option>
          ))}
        </select>
      </label>
      <div
        css={privacyPolicyCss}
        data-visibility={isGDPRCountry ? 'visible' : 'none'}
      >
        <input
          type="checkbox"
          id="edit-emeaconsentcookiesubmitted"
          name="eMEAconsentcookiesubmitted"
          value="yes"
          className="form-checkbox required"
          ref={register({ required: isGDPRCountry })}
        />
        <label className="option" htmlFor="edit-emeaconsentcookiesubmitted">
          <CsodBlockContent blocks={gdprText} />
          <span className="required-asterisk" />
        </label>
      </div>
      <div
        css={checkboxCss}
        data-visibility={isEmailOptIn ? 'visible' : 'none'}
      >
        <input
          type="checkbox"
          id="edit-emailpreferences"
          name="emailPreferences"
          value="EVT; WBR; WP;"
          className="form-checkbox"
          ref={register}
        />
        <label className="option" htmlFor="edit-emailpreferences">
          {emailOptInText}
        </label>
      </div>
    </div>
  );
};

CountryFormField.propTypes = {
  emailOptInText: PropTypes.string,
  errors: PropTypes.shape({}).isRequired,
  formFieldCss: PropTypes.shape({}).isRequired,
  gdprText: PropTypes.arrayOf(PropTypes.shape({})),
  marketoFieldName: PropTypes.string,
  register: PropTypes.func.isRequired,
  textLabel: PropTypes.string.isRequired,
};

CountryFormField.defaultProps = {
  emailOptInText: 'Opt in to email communications from Cornerstone',
  gdprText: [
    {
      _key: 'ac7d78ba2723',
      _type: 'block',
      children: [
        {
          _key: 'ac7d78ba27230',
          _type: 'span',
          marks: [],
          text:
            'I consent with the general conditions of use described by Cornerstone OnDemand ',
        },
        {
          _key: 'ac7d78ba27231',
          _type: 'span',
          marks: ['434d25099658'],
          text: 'Privacy Policy',
        },
        {
          _key: 'ac7d78ba27232',
          _type: 'span',
          marks: [],
          text: '',
        },
      ],
      markDefs: [
        {
          _key: '434d25099658',
          _type: 'link',
          href: '/privacy-policy',
        },
      ],
      style: 'normal',
    },
  ],
  marketoFieldName: '',
};
export default CountryFormField;

import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';

const tableRowCss = css`
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
`;

const TableRow = ({ children }) => {
  return <tr css={tableRowCss}>{children}</tr>;
};

TableRow.propTypes = {
  children: PropTypes.element.isRequired,
};

export default TableRow;

import PropTypes from 'prop-types';
/** @jsx jsx this comment is required for Storybook to render */
import { jsx } from '@emotion/core';

const DropdownFormField = ({
  errors,
  formFieldCss,
  marketoFieldName,
  onChange,
  optional,
  options,
  placeholder,
  register,
  textLabel,
}) => {
  const formName = marketoFieldName || textLabel;

  const handleChange = event => {
    // return false if there is no onChange prop
    if (!onChange) return false;

    const { selectedIndex } = event.target.options;
    let selectedSfid = event.target.options[selectedIndex].getAttribute('sfid');
    // Reset to original SFID
    // if the selected option doesn't have an SFID
    if (selectedSfid === null) selectedSfid = 'reset';
    // Invoke the callback with the new SFID value
    onChange(selectedSfid);

    return false;
  };

  return (
    <label css={formFieldCss} htmlFor={formName} required={!optional}>
      <span>{textLabel}</span>
      <select
        name={formName}
        className={
          Object.keys(errors).includes(formName) ? 'input-error' : null
        }
        defaultValue=""
        ref={register({ required: !optional })}
        onChange={handleChange}
      >
        {placeholder && (
          <option value="" disabled hidden>
            {placeholder}
          </option>
        )}
        {options.map(option => (
          <option
            key={option._key}
            value={option.value || option.textLabel}
            sfid={option.sfid}
          >
            {option.textLabel}
          </option>
        ))}
      </select>
    </label>
  );
};

DropdownFormField.propTypes = {
  errors: PropTypes.shape({}).isRequired,
  formFieldCss: PropTypes.shape({}),
  marketoFieldName: PropTypes.string,
  onChange: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  optional: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  placeholder: PropTypes.string,
  register: PropTypes.func.isRequired,
  textLabel: PropTypes.string.isRequired,
};

DropdownFormField.defaultProps = {
  formFieldCss: {},
  marketoFieldName: '',
  onChange: false,
  optional: false,
  placeholder: '',
};

export default DropdownFormField;

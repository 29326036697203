import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import {
  colors,
  fontFamilies,
  fontSizes,
  lineHeights,
} from '../../../styles/base';

const Text = styled.p`
  color: ${props => props.color};
  font-size: ${props => props.fontSize};
  font-family: ${fontFamilies.roboto};
  line-height: ${lineHeights.copy};
`;

Text.propTypes = {
  color: PropTypes.string,
  fontSize: PropTypes.string,
};

Text.defaultProps = {
  color: colors.mediumGray,
  fontSize: fontSizes.fifteen,
};

export default Text;

/* globals window */

export const isMobileMenu = () => window.innerWidth <= 1024;

export const isMobile = () => window.innerWidth <= 768;

export const getQueryStringParams = query => {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
        .split('&')
        .reduce((params, param) => {
          const [key, value] = param.split('=');
          const newParams = params;
          newParams[key] = value
            ? decodeURIComponent(value.replace(/\+/g, ' '))
            : '';
          return newParams;
        }, {})
    : {};
};

export default {
  isMobileMenu,
  isMobile,
  getQueryStringParams,
};

import PropTypes from 'prop-types';
/** @jsx jsx needed to get Storybook to render */
import { css, jsx } from '@emotion/core';
import {
  breakpoints,
  colors,
  fontFamilies,
  fontWeights,
  lineHeights,
  unitConverter as uc,
} from '../../../styles/base';
import Image from '../../atoms/Image/Image';
import sanityImage from '../../../utils/sanityImage';

const Quote = ({
  authorTitle,
  authorTitleColor,
  className,
  clientDrawing,
  clientQuote,
  drawingWidth,
  orientation,
  quoteAuthor,
}) => {
  const clientQuoteCss = css`
    position: relative;
    display: flex;
    flex-direction: row;
    font-family: ${fontFamilies.roboto};

    img {
      align-self: center;
      margin-right: ${uc('20px')};
    }

    figure {
      max-width: ${uc(`${730 - parseInt(drawingWidth, 10)}px`)};
    }

    @media (${breakpoints.tablet}) {
      width: auto;
    }
  `;

  const verticalClientQuoteCss = css`
    position: relative;
    display: flex;
    flex-direction: column;
    width: ${uc('300px')};
    font-family: ${fontFamilies.roboto};

    figcaption {
      padding-top: ${uc('25px')};
    }
  `;

  const verticalFigureCss = css`
    display: flex;
    flex-direction: row;
    font-family: ${fontFamilies.roboto};
  `;

  const blockquoteCss = css`
    max-width: ${uc('410px')};
    margin: ${uc('0 0 20px 0')};
    padding: 0;
    color: ${colors.lightGray.five};
    font-weight: ${fontWeights.medium};
    line-height: ${lineHeights.copy};
  `;

  const verticalBlockquoteCss = css`
    max-width: ${uc('410px')};
    margin: ${uc('0 0 0 0')};
    padding: 0;
    color: ${colors.lightGray.five};
    line-height: ${lineHeights.copy};
  `;

  const authorTitleCss = css`
    color: ${authorTitleColor};
  `;

  const quoteAuthorCss = css`
    margin-bottom: ${uc('6px')};
    color: ${colors.darkGray.three};
    font-family: ${fontFamilies.roboto};
  `;

  const figcaptionCss = css`
    font-weight: ${fontWeights.bold};
  `;

  if (orientation === 'vertical') {
    return (
      <div css={verticalClientQuoteCss} className={className}>
        <blockquote
          css={verticalBlockquoteCss}
        >{`"${clientQuote}"`}</blockquote>

        <figure css={verticalFigureCss}>
          {clientDrawing.asset && (
            <Image
              src={sanityImage(clientDrawing)
                .auto('format')
                .width(drawingWidth)
                .url()}
            />
          )}
          <figcaption css={figcaptionCss}>
            <div css={quoteAuthorCss}>{quoteAuthor}</div>
            <div css={authorTitleCss}>{authorTitle}</div>
          </figcaption>
        </figure>
      </div>
    );
  }

  return (
    <div css={clientQuoteCss} className={className}>
      {clientDrawing.asset && (
        <Image
          src={sanityImage(clientDrawing)
            .auto('format')
            .width(drawingWidth)
            .url()}
        />
      )}

      <figure>
        <blockquote css={blockquoteCss}>{`"${clientQuote}"`}</blockquote>
        <figcaption css={figcaptionCss}>
          <div css={quoteAuthorCss}>{quoteAuthor}</div>
          <div css={authorTitleCss}>{authorTitle}</div>
        </figcaption>
      </figure>
    </div>
  );
};

Quote.propTypes = {
  drawingWidth: PropTypes.number,
  clientDrawing: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({}).isRequired,
  ]),
  clientQuote: PropTypes.string,
  quoteAuthor: PropTypes.string,
  authorTitle: PropTypes.string,
  orientation: PropTypes.string,
  className: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  authorTitleColor: PropTypes.string,
};

Quote.defaultProps = {
  drawingWidth: 110,
  clientDrawing: '',
  clientQuote: '',
  quoteAuthor: '',
  authorTitle: '',
  orientation: 'horizontal',
  className: '',
  authorTitleColor: colors.darkGray.one,
};

export default Quote;
